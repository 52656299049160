import { collection, doc, getDocs } from "firebase/firestore";

let _db: any, _liveDB:any, _devStorage:any, _liveStorage:any;
let _devauth: any;
export default class Config {

    constructor() {

    }

    static setDevStorage (storage:any) {
        _devStorage = storage;
    }
    static getDevStorage () {
        return _devStorage;
    }
    static setLiveStorage (storage:any) {
        _liveStorage = storage;
    }
    static getLiveStorage () {
        return _liveStorage;
    }

    static setDevDB (db:any) {
        _db = db;
    }
    static getDevDB () {
        return _db;
    }

    static setLiveDB (db:any) {
        _liveDB = db;
    }
    static getLiveDB () {
        return _liveDB;
    }

    static setDevAuth (auth:any) {
        _devauth = auth;
    }
    static getDevAuth () {
        return _devauth;
    }


    static async getUserRoles (_user:any) {
        let publicSubcolRef = collection(doc(Config.getDevDB(), "users", String(_user?.uid)), "public");
        let sub = await getDocs(publicSubcolRef);
        let roles: any[] = [];  
        sub.forEach ( (doc) => {
            roles.push(doc.data().roles);
        })
        return roles[0];
    }

    static supportedLanguages () {
        let l = {
            "en-US": "English (United States)",
            "de-DE": "German"
          };

       return l;   
    }
   
}