import { buildSchema, EntityReference,buildProperty, ExtraActionsParams, buildCollection, AdditionalColumnDelegate } from "@camberi/firecms";
import { Button, Tooltip } from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Info from '@mui/icons-material/Info';
import downloadImages from "../utils/downloadImages";
import cardsFeedbackMeta from "../utils/cardsFeedbackMeta";



type cardsfeedback = {
  
  Artist: string;
  Status:string;
  card:EntityReference;
  completedon:Date;
  Priority:string;
  comments:string;
  gameplaycomments:string;
  Image:string;
  defaultRenderedCard:string,
 // shared:boolean;
  type:string;
  scifi:boolean;
 // portrait:boolean;
  ethnicity: string;
  gender: string;
 // situational:boolean;
  Equipment:string;
}


let _setOpen:any;
let _setData:any;
export default class CardsFeedback { 
  static BuildCollection (setOpen:any, setData:any, isadmin:boolean, issuperadmin:boolean, groupname:string) {
    _setOpen = setOpen;
    _setData = setData;

    return buildCollection({
      path: "cardsfeedback",
      exportable:false,
      group:groupname,
      textSearchEnabled: true,
      extraActions:CardsFeedback.actions,
   //   initialSort: ["completedon", "desc"],
//                textSearchEnabled: true,
      schema: CardsFeedback.schema(),
      name: "Cards Feedback",
      
      permissions: ({entity, path, user, authController, context }) => {
        return ({
            read: true,
            create: issuperadmin,
            update: isadmin,
            delete: isadmin
        });
      }
    })
  }
  static actions(extraActionParams:ExtraActionsParams) {
    let disabled = (extraActionParams.selectionController.selectedEntities.length == 0) ? true : false;
    const downloadCards = (event: React.MouseEvent) => { 

      if (extraActionParams.selectionController.selectedEntities.length == 0) return;
      let downloadimages = new downloadImages(extraActionParams.context);
      downloadimages.get(extraActionParams.selectionController);      
   };

   const openModal = (event: React.MouseEvent) => { 
    let meta = new cardsFeedbackMeta (extraActionParams.context, _setData);
    _setOpen(true);
    };

    return [
      <Tooltip title="Download selected images" key="printtip">
        <Button disabled={disabled} onClick={downloadCards} color="primary" key="print">
            <CloudDownloadIcon/>
        </Button>
      </Tooltip>,
      <Tooltip title="get info on progress" key="metatip">
        <Button onClick={openModal} color="primary" key="meta">
          <Info />
        </Button>
      </Tooltip>
     ];
  }
    static schema () {
        return buildSchema<cardsfeedback>({
          name: "Cards Feedback",
          
          properties: {
            
            card: {
              title: "card",
              dataType: "reference", 
              path: "cards",
              previewProperties: ["name", "syndicate", "type", "subtype", "abilities"],
              columnWidth: 500,
            },
            defaultRenderedCard: buildProperty({ // The `buildProperty` method is an utility function used for type checking
              title: "rendered card",
             
              dataType: "string",

              config: {
                  storageMeta: {
                      mediaType: "image",
                      storagePath: "renderedcards",
                      acceptedFiles: ["image/*"],
                      maxSize:1000000,
                      metadata: {
                        customMetadata: {
                          'visibility': 'admin'
                        }
                    }
                      
                  }
              }
            }),
            
            Image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
              title: "Card image (2048x2048)",
             
              dataType: "string",

              config: {
                  storageMeta: {
                      mediaType: "image",
                      storagePath: "artistcards",
                      acceptedFiles: ["image/*"],
                      maxSize:1000000,
                      metadata: {
                        customMetadata: {
                          'visibility': 'admin'
                        }
                    }
                      
                  }
              }
            }),
            Equipment: buildProperty({ // The `buildProperty` method is an utility function used for type checking
              title: "Card equipment, optional (2048x2048)",
             
              dataType: "string",

              config: {
                  storageMeta: {
                      mediaType: "image",
                      storagePath: "artistcardequipment",
                      maxSize:1000000,
                      acceptedFiles: ["image/*"]
                  }
              }
            }),
            Priority: {
              title: "Priority",
              dataType: "string",
              config: {
                enumValues: {
                    urgent: "urgent",
                    high: "high",
                    normal: "normal",
                    empty: "-"
                }
              }
            },
            Status: {
              title: "Status",
              dataType: "string",
              config: {
                enumValues: {
                    open: "open",
                    inprogress: "in progress",
                    review: "review",
                    done: "done"
                }
              }
            },
            Artist: {
              title: "Artist",
              dataType: "string",
              config: {
                enumValues: {
                    unassigned: "unassigned",
                    franklin: "Franklin",
                    leo: "Leo",
                    adelia: "Adelia",
                    iggu: "Iggu",
                    envar: "Envar",
                    zai: "Zai"
                }
              }
            },
            
            type: {
              title: "Type",
              dataType: "string",
              config: {
                enumValues: {
                    portrait: "portrait",
                    situational: "situational",
                    closeup_action: "close-up action",
                    places: "places",
                    equipment: "equipment",
                    fullbody: "fullbody character",
                }
              }
            },
            scifi: {
              title: "Sci-Fi themed",
              dataType: "boolean",
            },
            ethnicity: {
              title: "Ethnicity",
              dataType: "string",
              config: {
                enumValues: {
                    asian: "Asian",
                    black: "Black",
                    mixed: "Mixed",
                    white: "White",
                    other: "Other"
                }
              }
            },
            gender: {
              title: "Gender",
              dataType: "string",
              config: {
                enumValues: {
                    male: "male",
                    female: "female",
                    transgender: "transgender",
                    agender: "agender",
                    intersex: "intersex",
                    genderfluid: "gender fluid",
                    genderqueer: "gender queer"
                }
              }
            },
           
            
            completedon: {
              title: "updated on",
              dataType: "timestamp",
              autoValue:"on_update"
            },
            comments: {
              title: "Art comments",
              dataType: "string",
              columnWidth: 300,
              config: {
                multiline: true
              },
            },
            gameplaycomments: {
              title: "Gameplay comments",
              dataType: "string",
              columnWidth: 300,
              config: {
                multiline: true
              },
            },
            // situational: {
            //   title: "Situational",
            //   dataType: "boolean",  
            // },
            // portrait: {
            //   title: "Portrait",
            //   dataType: "boolean",  
            // },

            
            // shared: {
            //   title: "Shared",
            //   dataType: "boolean",  
            // },
          }
        });
    }
}
