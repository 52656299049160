import {
  jsPDF
} from "jspdf";
import {
  DataSource,
  EntityReference,
  SelectionController,
  SnackbarController,
  StorageSource
} from "@camberi/firecms";
import Cards from "../collections/cards";

import Aggressor from '../assets/abilities_icons/Aggressor.png';
import Antivirus from '../assets/abilities_icons/Antivirus.png';
import BitThirsty from '../assets/abilities_icons/BitThirsty.png';
import Contagious from '../assets/abilities_icons/Contagious.png';
import DoubleIntrusion from '../assets/abilities_icons/DoubleIntrusion.png';
import Encrypted from '../assets/abilities_icons/Encrypted.png';
import Extraction from '../assets/abilities_icons/Extraction.png';
import Mirror from '../assets/abilities_icons/Mirror.png';
import Overflow from '../assets/abilities_icons/Overflow.png';
import PredictiveStrike from '../assets/abilities_icons/PredictiveStrike.png';
import Protector from '../assets/abilities_icons/Protector.png';
import Regenerate from '../assets/abilities_icons/Regenerate.png';
import Surge from '../assets/abilities_icons/Surge.png';
import Troll from '../assets/abilities_icons/Troll.png';




let pageHeight: number,
  pageWidth: number,
  startY: number,
  startX: number,
  prevX: number,
  prevY: number,
  pageMargin: number,
  cellHeight: number,
  cellMargin: number,
  cellWidth: number,


  _storage: StorageSource,
  _datasource: DataSource,
  _snackbar: SnackbarController,
  _fromset:boolean,


  cardsCreated: Event,
  cardsNrToPrint: number = 0,
  totalCardsToPrint = 0,
  card: EntityReference,
  doc: jsPDF;

let cardsToPrint = new Array();


export default class printCards {


  constructor(context: any) {
    _storage = context.storageSource;
    _datasource = context.dataSource;
    _snackbar = context.snackbarController;

    cardsCreated = new Event("cardsloaded");
    document.removeEventListener('cardsloaded',this.listener);
    document.addEventListener('cardsloaded',this.listener);

  }

  
  // CREATE A PDF OF A SELECTION OF CARDS
  public createCardsPDF(selectionController: SelectionController) {
    this.initDoc();


    for (var i = 0; i < selectionController.selectedEntities.length; i++) {
      this.createCard(selectionController.selectedEntities[i], selectionController.selectedEntities.length);
    }
  }


  // CREATE A PDF OF A DECK'S CARDS
  public async createDecksPDF(selectionController: SelectionController, fromset:boolean) {
    _fromset = fromset;
    this.initDoc();


    _snackbar.open({
      type: "info",
      title: "Fetching Cards",
      message: "getting cards from firebase"
    });

    for (var i = 0; i < selectionController.selectedEntities.length; i++) {

      for (card of selectionController.selectedEntities[i].values.cards) {
        await this.fetchcard(card);
      }
    }
    setTimeout(() => {
      _snackbar.open({
        type: "info",
        title: "Cards fetched",
        message: "preparing cards to print"
      });

      this.deckToPDF();
    }, 1000);
  }



  private initDoc () {
    doc = new jsPDF('p', 'pt', 'a4');
    //Dimension of A4 in pts: 595 × 842
    doc.setFontSize(9);
    pageWidth = 595;
    pageHeight = 842;

    pageMargin = 20;

    pageWidth -= pageMargin * 2;
    pageHeight -= pageMargin * 2;

    cellMargin = 10;
    cellWidth = 167; //180;
    cellHeight = 243; //60;

    startX = pageMargin;
    startY = pageMargin;
  }

  private listener () {
    document.removeEventListener('cardsloaded', this.listener);
    cardsNrToPrint = 0;
    doc.save("grid.pdf");
  }

  private async fetchcard(obj: any) {
    _datasource.fetchEntity({
        path: (_fromset) ? obj.path : obj.cards.path,
        entityId: (_fromset) ? obj.id : obj.cards.id,
        schema: Cards.schema()
      })
      .then((results) => {
        
        if(_fromset) totalCardsToPrint++;
        if(!_fromset) totalCardsToPrint += obj.amount;
        
        cardsToPrint.push({
          amount: (_fromset) ? 1 : obj.amount,
          card: results
        });
      })
  }

  private deckToPDF() {
    
    for (let i = 0; i < cardsToPrint.length; i++) {
      for (let j = 0; j < cardsToPrint[i].amount; j++) {
        if (!_fromset) this.createCard(cardsToPrint[i].card, totalCardsToPrint);
        if (_fromset) this.createCardForSet(cardsToPrint[i].card, totalCardsToPrint);
      }
    }
  }


  private createCardForSet(item: {
    id: any;values: any
  }, totalCards: number) {
    
    //console.log (item.values.renderedcards[0].visual);

    console.log (item.values.defaultRenderedCard);
    if (item.values.defaultRenderedCard != "" && item.values.defaultRenderedCard != undefined) {
      

      _storage.getDownloadURL(item.values.defaultRenderedCard).then((result: any) => {

        var img = new Image();
        img.src = String(result);
        this.createCells(item);

        doc.addImage(img, 'png', prevX , prevY , cellHeight/2 , cellHeight );
        cardsNrToPrint++;
        if (cardsNrToPrint >= totalCards) {
          console.log ("CARDS READY");
          document.dispatchEvent(cardsCreated);
        }

      });
    } else {
      this.createFields(item);
      cardsNrToPrint++;
      if (cardsNrToPrint >= totalCards) {
        console.log ("CARDS READY");
        document.dispatchEvent(cardsCreated);
      }


    }
  }


  private createCard(item: {
    id: any;values: any
  }, totalCards: number) {


    console.log (item.values.defaultRenderedCard);
    if (item.values.defaultRenderedCard != "" && item.values.defaultRenderedCard != undefined) {
      

      _storage.getDownloadURL(item.values.defaultRenderedCard).then((result: any) => {

        var img = new Image();
        //  console.log (imgurl);
        img.src = String(result);
        this.createCells(item);

        doc.addImage(img, 'png', prevX , prevY , cellHeight/2 , cellHeight );
        cardsNrToPrint++;
        if (cardsNrToPrint >= totalCards) {
          console.log ("CARDS READY");
          document.dispatchEvent(cardsCreated);
        }

      });
    } else {
      this.createFields(item);
      cardsNrToPrint++;
      if (cardsNrToPrint >= totalCards) {
        console.log ("CARDS READY");
        document.dispatchEvent(cardsCreated);
      }


    }
  }


  private createCells(item: {id: any;values: any}) {
    // doc.getTextDimensions(item.Name); turncate or split string if you needed
    
    if (startY + cellHeight >= pageHeight + pageMargin + (cellMargin * 2)) {
      doc.addPage();
      startY = pageMargin; // Restart height position
    }

    prevX = startX;
    prevY = startY;
   

    var nextPosX = startX + cellWidth + cellMargin;

    if (nextPosX > pageWidth - pageMargin - (cellMargin * 2)) {
      startX = pageMargin;
      startY += cellHeight + cellMargin;
    } else {
      startX = nextPosX;
    }
  }


  private createFields(item: {id: any;values: any}) {
    // doc.getTextDimensions(item.Name); turncate or split string if you needed
    
    if (startY + cellHeight >= pageHeight + pageMargin + (cellMargin * 2)) {
      doc.addPage();
      startY = pageMargin; // Restart height position
    }

    prevX = startX;
    prevY = startY;
    doc.setDrawColor(0);

    // black borders
    doc.rect(startX, startY, cellWidth, cellHeight); 
    
    
    // MANA
    doc.setFont('times', 'bold');
    doc.text(String(item.values.cost.mana), startX + 10, startY + 12);
    doc.circle(startX+12, startY+10, 8, 'S');

    // SYNDICATE
    if (item.values.syndicate != "None") {
      doc.setFont('times', 'normal');
      let syndicate = item.values.syndicate.replace(/([A-Z])/g, " $1");
      let syndicate_split = syndicate.charAt(0).toUpperCase() + syndicate.slice(1);
      doc.text(syndicate_split, startX + 40, startY + 12, {
        maxWidth: cellWidth - 20
      });
    }

    // CARD NAME & SUBTYPE
    doc.setFont('times', 'bold');
    doc.setFontSize(11);
    let subtype = item.values.subtype.replace(/([A-Z])/g, " $1");
    let subtype_split = subtype.charAt(0).toUpperCase() + subtype.slice(1);
    doc.text(item.values.name + " • " + subtype_split, startX + 10, startY + cellWidth-20, {
      maxWidth: cellWidth - 10
    });
    
    // DESCRIPTION
    doc.setFontSize(8);
    doc.setFont('times', 'normal');
    doc.text(item.values.description, startX + 10, startY + cellWidth+10, {
      maxWidth: cellWidth - 20
    });
    

    // ABILITIES
    let i = 0;
    var img = new Image();
    item.values.abilities.forEach( (e: any) => {
      if (e == "Aggressor") img.src = Aggressor;
      if (e == "Antivirus") img.src = Antivirus;
      if (e == "Bit Thirsty") img.src = BitThirsty;
      if (e == "Contagious") img.src = Contagious;
      if (e == "Double Intrusion") img.src = DoubleIntrusion;
      if (e == "Encrypted") img.src = Encrypted;
      if (e == "Extraction") img.src = Extraction;
      if (e == "mirror") img.src = Mirror;
      if (e == "Overflow") img.src = Overflow;
      if (e == "Predictive Strike") img.src = PredictiveStrike;
      if (e == "Protector") img.src = Protector;
      if (e == "Regenerate") img.src = Regenerate;
      if (e == "Surge") img.src = Surge;
      if (e == "Troll") img.src = Troll;
      doc.addImage(img, 'png', (startX+40) + (30*i), startY+cellHeight-30, 25, 25);
      i++;
    })
    
    
    if (item.values.syndicate != "None") {
      // ATTACK
      doc.setFont('times', 'bold');
      doc.setFontSize(9);
      doc.text(String(item.values.attack), startX + 10, startY + cellHeight - 10);
      doc.circle(startX+12, startY+cellHeight - 12, 8, 'S');
      // DEFENSE
      doc.text(String(item.values.health), startX + cellWidth - 12, startY + cellHeight - 10);
      doc.circle(startX+cellWidth-10, startY+cellHeight - 12, 8, 'S');
    }


    var nextPosX = startX + cellWidth + cellMargin;

    if (nextPosX > pageWidth - pageMargin - (cellMargin * 2)) {
      startX = pageMargin;
      startY += cellHeight + cellMargin;
    } else {
      startX = nextPosX;
    }
  }
}