import { Avatar, Card, CardContent, CardHeader, Typography } from "@mui/material";
import * as React from 'react';
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { red } from '@mui/material/colors';
import parse from 'html-react-parser';

import { Box } from "@mui/system";


export default function createCard (primary:any, secondary:any, labels:any, values:any) {
    let letterAvatar = primary.substring(0,1).toUpperCase();
    return (
      <Card sx={{ m: 2, width:300,maxWidth: 345}} >
  
        <CardHeader title={primary} 
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">{letterAvatar}</Avatar>
        }>
          
        </CardHeader>
        
        <CardContent>
        { createPieChart(labels, values) }
          <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: "pre-line"}} >
            { parse(secondary) }
          </Typography>
        </CardContent>
  </Card>
    )
  }

  const createPieChart = ( _labels:any, values:any ) =>  {
    ChartJS.register(ArcElement, Tooltip);
  
    const data = {
      labels: _labels,
      datasets: [
        {
          label: '# ',
          data: values,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }
    
    return (
      <Box sx={{m:2,minHeight:200,marginBottom:5}}>
      <Pie 
        data={data}
      />
      </Box>
    );
  }