import { SelectionController,DataSource, SnackbarController } from "@camberi/firecms";
import Cards from "../collections/cards";
import { collection, query, where, getDocs, getDoc, DocumentReference } from "firebase/firestore";
import config from "../utils/config";
import { validateNamespace } from "@firebase/util";
let _datasource : DataSource;
let _snackbar : SnackbarController;



let info = {
  cardsfeedback:true,
  header: "cards",
  scifi:0,
  general: {
    public:0,
    totalCards: 0,
    finishedCards: 0,
    inprogressCards: 0,
    toberevised: 0,
    software: 0,
    glitch: 0
  },

  
  type: {
    portrait:0,
    situational:0,
    closeup_action:0,
    places:0,
    equipment:0,
    fullbody:0
  },
  ethnicity: {
    asian: 0,
    black: 0,
    mixed: 0,
    white: 0,
    other: 0
  },

  gender: {
    male: 0,
    female: 0,
    transgender: 0,
    agender: 0,
    intersex: 0,
    genderfluid:0,
    genderqueer: 0
  },


  syndicates: {
    AdamantHands: 0,
    SentinelsOfEternity: 0,
    CuratorsMaxima:0,
    SongOfTheChain: 0,
    GuardiansOfTheSource:0
  },

  artists: {
    Franklin: {
      done: 0,
      inprogress:0,
      notuploaded: 0,
      toberevised: 0
    },
    Zai: {
      done: 0,
      inprogress:0,
      notuploaded: 0,
      toberevised: 0
    },
    Leo: {
      done: 0,
      inprogress:0,
      notuploaded: 0,
      toberevised: 0
    },
    Adelia: {
      done: 0,
      inprogress:0,
      notuploaded: 0,
      toberevised: 0
    },
    Iggu: {
      done: 0,
      inprogress:0,
      notuploaded: 0,
      toberevised: 0
    },
    Envar: {
      done: 0,
      inprogress:0,
      notuploaded: 0,
      toberevised: 0
    }
  },

  priority: {
    urgenttotal: 0,
    urgentdone: 0,
    hightotal: 0,
    highdone: 0,
    normaltotal: 0,
    normaldone: 0,
    urgentinprogress: 0,
    normalinprogress: 0,
    highinprogress: 0,
  }
};

let _setData: any;
export default class cardsFeedbackMeta {
    constructor(context: any, setData:any) {
        _datasource = context.dataSource;
        _snackbar = context.snackbarController;
        _setData = setData;
        this.resetData();
        this.getAllData();
        
    }
    public async getAllData () {
      let querySnapshot = await getDocs(collection(config.getDevDB(), "cardsfeedback"));
      let allCards:any = [];
      querySnapshot.forEach((doc) => {
          info.general.totalCards++;
          
          // type          
          if (doc.data().type == "portrait") info.type.portrait++;
          if (doc.data().type == "situational") info.type.situational++;
          if (doc.data().type == "closeup_action") info.type.closeup_action++;
          if (doc.data().type == "places") info.type.places++;
          if (doc.data().type == "equipment") info.type.equipment++;
          if (doc.data().type == "fullbody") info.type.fullbody++;
          
          
          // ethnicity
          if (doc.data().scifi == true) info.scifi++;

          // ethnicity
          if (doc.data().ethnicity == "asian") info.ethnicity.asian++;
          if (doc.data().ethnicity == "black") info.ethnicity.black++;
          if (doc.data().ethnicity == "mixed") info.ethnicity.mixed++;
          if (doc.data().ethnicity == "white") info.ethnicity.white++;
          if (doc.data().ethnicity == "other") info.ethnicity.other++;


          // gender
          if (doc.data().gender == "male") info.gender.male++;
          if (doc.data().gender == "female") info.gender.female++;
          if (doc.data().gender == "transgender") info.gender.transgender++;
          if (doc.data().gender == "agender") info.gender.agender++;
          if (doc.data().gender == "intersex") info.gender.intersex++;
          if (doc.data().gender == "genderfluid") info.gender.genderfluid++;
          if (doc.data().gender == "genderqueer") info.gender.genderqueer++;


          // inprogress
          if (doc.data().Status == "inprogress") info.general.inprogressCards++;
          if (doc.data().Status == "inprogress" && doc.data().Artist == "franklin") info.artists.Franklin.inprogress++;
          if (doc.data().Status == "inprogress" && doc.data().Artist == "adelia") info.artists.Adelia.inprogress++;
          if (doc.data().Status == "inprogress" && doc.data().Artist == "leo") info.artists.Leo.inprogress++;
          if (doc.data().Status == "inprogress" && doc.data().Artist == "zai") info.artists.Zai.inprogress++;


          // review
          if (doc.data().Status == "review") info.general.toberevised++;
          if (doc.data().Status == "review" && doc.data().Artist == "franklin") info.artists.Franklin.toberevised++;
          if (doc.data().Status == "review" && doc.data().Artist == "leo") info.artists.Leo.toberevised++;
          if (doc.data().Status == "review" && doc.data().Artist == "adelia") info.artists.Adelia.toberevised++;
          if (doc.data().Status == "review" && doc.data().Artist == "zai") info.artists.Zai.toberevised++;
          
          // done
          if (doc.data().Status == "done") info.general.finishedCards++;
          if (doc.data().Status == "done" && doc.data().Artist == "franklin") info.artists.Franklin.done++;
          if (doc.data().Status == "done" && doc.data().Artist == "adelia") info.artists.Adelia.done++;
          if (doc.data().Status == "done" && doc.data().Artist == "leo") info.artists.Leo.done++;
          if (doc.data().Status == "done" && doc.data().Artist == "zai") info.artists.Zai.done++;


          // not uploaded          
          if (doc.data().Status == "done" && doc.data().Artist == "franklin" && (doc.data().Image == "" || doc.data().Image == undefined)) info.artists.Franklin.notuploaded++;
          if (doc.data().Status == "done" && doc.data().Artist == "zai" && (doc.data().Image == "" || doc.data().Image == undefined)) info.artists.Zai.notuploaded++;
          if (doc.data().Status == "done" && doc.data().Artist == "leo" && (doc.data().Image == "" || doc.data().Image == undefined)) info.artists.Leo.notuploaded++;
          if (doc.data().Status == "done" && doc.data().Artist == "adelia" && (doc.data().Image == "" || doc.data().Image == undefined)) info.artists.Adelia.notuploaded++;
          
          // Priority
          if (doc.data().Priority == "normal") info.priority.normaltotal++;
          if (doc.data().Priority == "high") info.priority.hightotal++;
          if (doc.data().Priority == "urgent") info.priority.urgenttotal++;

          if ((doc.data().Status == "done" || doc.data().Status == "review") && doc.data().Priority == "normal") info.priority.normaldone++;
          if ((doc.data().Status == "done" || doc.data().Status == "review") && doc.data().Priority == "urgent") info.priority.urgentdone++;
          if ((doc.data().Status == "done" || doc.data().Status == "review") && doc.data().Priority == "high") info.priority.highdone++;

          if (doc.data().Status == "inprogress" && doc.data().Priority == "normal") info.priority.normalinprogress++;
          if (doc.data().Status == "inprogress" && doc.data().Priority == "urgent") info.priority.urgentinprogress++;
          if (doc.data().Status == "inprogress" && doc.data().Priority == "high") info.priority.highinprogress++;


          //if (doc.data().Image != undefined) {
            allCards.push (doc.data());
            // if (doc.data().portrait) info.general.finishedportrait++;
            // if (doc.data().situational) info.general.finishedsituational++;
          //}

          
      });

      
      let cardsPromise = await Promise.all(allCards.map( (doc:any) => {
        if (doc.undefined) return;
        if (doc.card == null) return;
        return getDoc(doc.card);
      }) )

      cardsPromise.map ( (card:any) => {
        if (card == undefined) return;
        
        
        let c = card.data();
        
        if (c == undefined) return;
        if (c.public == true) info.general.public++;
        if (c.type == "Glitch") info.general.glitch++;
        if (c.type == "Software") info.general.software++;
        if (c.syndicate != "None" && c.syndicate == "AdamantHands") info.syndicates.AdamantHands++;
        if (c.syndicate != "None" && c.syndicate == "SentinelsOfEternity") info.syndicates.SentinelsOfEternity++;
        if (c.syndicate != "None" && c.syndicate == "CuratorsMaxima") info.syndicates.CuratorsMaxima++;
        if (c.syndicate != "None" && c.syndicate == "SongOfTheChain") info.syndicates.SongOfTheChain++;
        if (c.syndicate != "None" && c.syndicate == "GuardiansOfTheSource") info.syndicates.GuardiansOfTheSource++;
      })
      info.cardsfeedback = true;
      info.header = "Cards";
       console.log ("COMPLETE ALL", info);

       _setData(info);
       

     // this.dataToModal();
    }
    



    private resetData () {
      info = {
        cardsfeedback:true,
        header: "cards",
        scifi:0,
        general: {
          public:0,
          totalCards: 0,
          finishedCards: 0,
          inprogressCards: 0,
          toberevised: 0,
          //shared: 0,
         // portrait: 0,
         // finishedportrait:0,
          //situational: 0,
        //  finishedsituational:0,
          software: 0,
          glitch: 0
        },
        type: {
          portrait:0,
          situational:0,
          closeup_action:0,
          places:0,
          equipment:0,
          fullbody:0
        },
        syndicates: {
          AdamantHands: 0,
          SentinelsOfEternity: 0,
          CuratorsMaxima:0,
          SongOfTheChain: 0,
          GuardiansOfTheSource:0
        },
        ethnicity: {
          asian: 0,
          black: 0,
          mixed: 0,
          white: 0,
          other: 0
        },
      
        gender: {
          male: 0,
          female: 0,
          transgender: 0,
          agender: 0,
          intersex: 0,
          genderfluid:0,
          genderqueer: 0
        },
      
        artists: {
          Franklin: {
            done: 0,
            inprogress:0,
            notuploaded: 0,
            toberevised: 0
          },
          Adelia: {
            done: 0,
            inprogress:0,
            notuploaded: 0,
            toberevised: 0
          },
          Iggu: {
            done: 0,
            inprogress:0,
            notuploaded: 0,
            toberevised: 0
          },
          Envar: {
            done: 0,
            inprogress:0,
            notuploaded: 0,
            toberevised: 0
          },
          Zai: {
            done: 0,
            inprogress:0,
            notuploaded: 0,
            toberevised: 0
          },
          Leo: {
            done: 0,
            inprogress:0,
            notuploaded: 0,
            toberevised: 0
          }
        },

        priority: {
          urgentdone: 0,
          highdone:0,
          normaldone:0,
          urgenttotal: 0,
          hightotal:0,
          normaltotal:0,
          normalinprogress:0,
          highinprogress:0,
          urgentinprogress:0
        }
      };
    }


}