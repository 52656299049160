import { Dialog, Grid } from "@mui/material";
import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { Line } from "react-chartjs-2";
import ModalPartialCard from './ModalPartialCard';

const parseGenesisBoosterSales = (salesarray:any, pack:any) => {
    let drop1=0, drop2=0, drop3=0, drop4=0, drop5=0, drop6=0;
    if (pack.availability_01 != undefined) drop1 = 1000000 - pack.availability_01;
    if (pack.availability_02 != undefined) drop2 = 1000000 - pack.availability_02;
    if (pack.availability_03 != undefined) drop3 = 1000000 - pack.availability_03;
    if (pack.availability_04 != undefined) drop4 = 1000000 - pack.availability_04;
    if (pack.availability_05 != undefined) drop5 = 1000000 - pack.availability_05;
    if (pack.availability_06 != undefined) drop6 = 1000000 - pack.availability_06;  

    
    salesarray.push(drop1,drop2,drop3,drop4,drop5, drop6);  
}

const createGenesisLineChart = (  bronzesales: any, silversales:any, goldsales:any, totalsales:any) => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  
    let options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        }     
      },
    };
    
    let labels = ['drop#1', 'drop#2', 'drop#3', 'drop#4', 'drop#5', 'drop#6'];
  
    let data = {
      labels,
      datasets: [
        {
          label: 'Bronze',
          data: bronzesales,
          borderColor: 'rgb(148, 61, 10)',
          backgroundColor: 'rgba(148, 61, 10, 0.5)',
        },
        {
          label: 'Silver',
          data: silversales,
          borderColor: 'rgb(212,212,212)',
          backgroundColor: 'rgba(212,212,212, 0.5)',
        },
        {
          label: 'Gold',
          data: goldsales,
          borderColor: 'rgb(255, 204, 0)',
          backgroundColor: 'rgba(255, 204, 0, 0.5)',
        },
        {
          label: 'Total',
          data: totalsales,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };
  
  
    return (
        <Line options={options} data={data}  />
    );
}


const createMetricsTable = (metrics:any, rewards:any, totalUsers:any, currencyExchange:any) => {
  
  let ETHtoUSDC = (currencyExchange == undefined) ? 1 : parseFloat(currencyExchange.USDC);
  let LOSTtoUSDC = 0.00875;
  let totalLOSTSpent = 0;
  let totalETHSpent = 0;
  let totalUSDCSpent = 0;
  let participantsPaidInRealCurrency = 0;
  let participantsPaidOnlyInRealCurrency = 0;
  let participantsPaidOnlyInLOST = 0;
  let participantsPaidInLOSTandRealCurrency = 0;
  let totalETHNoLOST = 0;
  let totalUSDCNoLOST = 0;

  let totalBronzeNoLOST = 0;
  let totalSilverNoLOST = 0;
  let totalGoldNoLOST = 0;

  let totalLOSTSpentFromRewardReceivers = 0;
  let totalETHSpentFromRewardReceivers = 0;
  let totalUSDCSpentFromRewardReceivers = 0;

  let lowerCaseRewards:any = []; // convert rewards to lower case
  rewards.forEach( (r:any) => {
    lowerCaseRewards.push(r.toLowerCase());
  })
  
  metrics.forEach ((metric:any) => {
    totalLOSTSpent += metric.totalLOSTSpent;
    totalETHSpent += metric.totalETHSpent;
    totalUSDCSpent += metric.totalUSDCSpent;
    if (metric.totalLOSTSpent == 0) {
      totalETHNoLOST += metric.totalETHSpent;
      totalUSDCNoLOST += metric.totalUSDCSpent; 
      totalBronzeNoLOST += metric.totalBronzeBoosters;
      totalSilverNoLOST += metric.totalSilverBoosters;
      totalGoldNoLOST += metric.totalGoldBoosters;
    }
    if (metric.totalETHSpent > 0 || metric.totalUSDCSpent > 0 ) participantsPaidInRealCurrency++;
    if ((metric.totalETHSpent > 0 || metric.totalUSDCSpent > 0) && metric.totalLOSTSpent == 0 ) participantsPaidOnlyInRealCurrency++;
    if (metric.totalLOSTSpent > 0 && metric.totalETHSpent == 0 && metric.totalUSDCSpent == 0 ) participantsPaidOnlyInLOST++;
    if (metric.totalLOSTSpent > 0 && (metric.totalETHSpent > 0 || metric.totalUSDCSpent > 0) ) participantsPaidInLOSTandRealCurrency++;

   
    let found;
    if (metric.walletHash != undefined) {
      found = metric.walletHash.some((r: any)=> {
        return lowerCaseRewards.includes(r.toLowerCase());
      })
    }
    
    
    if (found) {
      totalLOSTSpentFromRewardReceivers += metric.totalLOSTSpent;
      totalETHSpentFromRewardReceivers += metric.totalETHSpent;
      totalUSDCSpentFromRewardReceivers += metric.totalUSDCSpent;
    }

  })


  let participation = "total $LOST spent: " + totalLOSTSpent.toFixed(2) + 
                      "\ntotal ETH spent: " + totalETHSpent.toFixed(4) + 
                      "\ntotal USDC spent: " + totalUSDCSpent.toFixed(2) + 
                      "\n\ntotal participants: " + metrics.length + 
                      "\ntotal registered users: " + totalUsers;
  
  let spending = "participants who paid in real currency: " + participantsPaidInRealCurrency + 
                "\n\nparticipants who paid ONLY in real currency: " + participantsPaidOnlyInRealCurrency + 
                "\n\nparticipants who paid ONLY in $LOST: " + participantsPaidOnlyInLOST +
                "\n\nparticipants who paid in $LOST & real currency: " + participantsPaidInLOSTandRealCurrency;

  let realCurrencySpending = "total ETH spent from participants who didn't spend $LOST: " + totalETHNoLOST.toFixed(4) + 
                            "\n\ntotal USDC spent from participants who didn't spend $LOST: " + totalUSDCNoLOST.toFixed(2);

  let realBoosterSpending = "total bronze packs opened from participants who didn't spend $LOST: " + totalBronzeNoLOST + 
                            "\n\ntotal silver packs opened from participants who didn't spend $LOST: " + totalSilverNoLOST + 
                            "\n\ntotal gold packs opened from participants who didn't spend $LOST: " + totalGoldNoLOST;
  
  let rewardReturn = "total $LOST spent from reward receivers: " + totalLOSTSpentFromRewardReceivers.toFixed(2) + 
                    "\n\ntotal ETH spent from reward receivers: " + totalETHSpentFromRewardReceivers.toFixed(4) +
                    "\n\ntotal USDC spent from reward receivers: " + totalUSDCSpentFromRewardReceivers.toFixed(2);
  return (
    <Grid container spacing={2} display="flex" sx={{m:2}}>
     {ModalPartialCard ("Currency spending", participation, ['$LOST IN USDC', 'ETH IN USDC', 'USDC'], [totalLOSTSpent*LOSTtoUSDC, totalETHSpent*ETHtoUSDC, totalUSDCSpent])}
     {ModalPartialCard ("User spending distribution", spending, ['real currency', 'only $LOST'], [participantsPaidInRealCurrency, participantsPaidOnlyInLOST] ) }
     {ModalPartialCard ("Only real currency spending", realCurrencySpending, ['ETH IN USDC', 'USDC'], [totalETHNoLOST*ETHtoUSDC, totalUSDCNoLOST] ) }
     {ModalPartialCard ("Booster distribution with real currency", realBoosterSpending, ['Bronze', 'Silver', 'Gold'], [totalBronzeNoLOST, totalSilverNoLOST, totalGoldNoLOST] ) }
     {ModalPartialCard ("Return on rewards", rewardReturn, ['$LOST IN USDC', 'ETH IN USDC', 'USDC'], [totalLOSTSpentFromRewardReceivers*LOSTtoUSDC, totalETHSpentFromRewardReceivers*ETHtoUSDC, totalUSDCSpentFromRewardReceivers] ) }
     </Grid>
 )
}


const createSalesPieChart = (bronze:any, silver:any, gold:any, total:any) => {
  let info = "bronze: " + bronze + 
            "\n\nsilver: " + silver + 
            "\n\ngold: " + gold + 
            "\n\ntotal: " + total;
  return (
    <Grid container spacing={2} display="flex" sx={{m:2}}>
     {ModalPartialCard ("Non-Genesis Booster Sales",info, ['bronze', 'silver', 'gold'], [bronze, silver, gold])}
     </Grid>
  )

};

export default function modalPartialBooster (props:any,  handleClose:any, appBar:any) {
    let bronzeGenesisSales:any = [];
    let silverGenesisSales:any = [];
    let goldGenesisSales:any = [];
    let totalGenesisSales:any = [];


    let bronzeNonGenesisSales:any = 0;
    let silverNonGenesisSales:any = 0;
    let goldNonGenesisSales:any = 0;
    let totalNonGenesisSales:any = 0;

    props.data.packs.forEach ( (pack:any) => {
      if (pack.type == "classic") {
        parseGenesisBoosterSales(bronzeGenesisSales,pack);
        bronzeNonGenesisSales = (pack.sales == undefined) ? 0 : pack.sales ;
      }
      if (pack.type == "exclusive") {
        parseGenesisBoosterSales(silverGenesisSales,pack);
        silverNonGenesisSales = (pack.sales == undefined) ? 0 : pack.sales;
      }
      if (pack.type == "collectors") {
        parseGenesisBoosterSales(goldGenesisSales,pack);
        goldNonGenesisSales = (pack.sales == undefined) ? 0 : pack.sales;
      } 
  
    });
    totalNonGenesisSales = bronzeNonGenesisSales + silverNonGenesisSales + goldNonGenesisSales;
    for (let i = 0; i < bronzeGenesisSales.length; i++) {
      let dropsale = bronzeGenesisSales[i] + silverGenesisSales[i] + goldGenesisSales[i];
      totalGenesisSales.push(dropsale);
    }


    props.data.header = "Booster pack sales";
  //   fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USDC")
  // .then((response) => response.json())
  // .then((data) => {
    
    
    return(
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}>
        
  
        {appBar(props, handleClose)}
        
        
        <Grid container spacing={2} display="flex">          
         { createMetricsTable(props.metrics, props.rewards, props.totalUsers, props.currencyExchange) } 
        </Grid>
        <Grid container spacing={2} display="flex" sx={{m:2,maxHeight:"80vh", display: 'flex', justifyContent:"center", alignItems: 'center'}}>          
        {createSalesPieChart(bronzeNonGenesisSales, silverNonGenesisSales, goldNonGenesisSales, totalNonGenesisSales)}
        </Grid>
        <Grid container spacing={2} display="flex" sx={{m:2,maxHeight:"80vh", display: 'flex', justifyContent:"center", alignItems: 'center'}}>          
        { createGenesisLineChart(bronzeGenesisSales, silverGenesisSales, goldGenesisSales, totalGenesisSales) }  
        </Grid>

        
      </Dialog>
    )
  }