import {  Dialog, Grid, IconButton, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import * as React from 'react';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';


export default function modalPartialComparisonTable (props:any, handleClose:any, appBar:any, sideEntityController:any) {
    return(
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}>
        
  
        {appBar(props, handleClose)}
        
        <Grid container spacing={2} display="flex" sx={{m:2}}>          
          
          
        </Grid>
        <Grid container spacing={2} display="flex">          
          { createComparisonTable( props.data.cardDiffrerences, sideEntityController) }
        </Grid>
      </Dialog>
    )
  }
  

  const createComparisonTable = (comparison:any, sideEntityController:any) => {
    
    return (
      <TableContainer sx={{m:4}} component={Paper} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="left">data on dev</TableCell>
              <TableCell align="left">data on live</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
            {comparison.map((item:any,i:any) => (
              
              
              <TableRow
                key={i+"_"+item.id}
                sx={{ background: (item.devRarity != item.liveRarity) ? "#f44336" : "auto", '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">
                  #{i+1} - {item.id}
                  <IconButton aria-label="settings" onClick={ (e) => {
                    e.preventDefault();
                    sideEntityController.open({ 
                      entityId: item.id,
                      path: "/cards"
                    })
                  }}>
                    <KeyboardTabIcon />
                  </IconButton>
                  </TableCell> 
                <TableCell align="center">
                <img src={item.devURL} width={200} height={400} loading="lazy" />
                  <Typography  >{item.devRarity}</Typography>
                </TableCell>
                  
                <TableCell align="center"> 
                <img src={item.liveURL} width={200} height={400} loading="lazy" /> 
                <Typography  >{item.liveRarity}</Typography>
                </TableCell>
                
              </TableRow>
            
            ))}
          </TableBody>
        </Table>
      </TableContainer> 
    )
  
  }