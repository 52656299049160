import { buildSchema, buildProperty, ExtraActionsParams, buildCollection } from "@camberi/firecms";
import { PictureAsPdf } from "@mui/icons-material";
import * as React from 'react';
import Info from "@mui/icons-material/Info";
import { Button } from "@mui/material";
import cardsMeta from "../utils/cardsMeta";
import printCards from "../utils/printCards";

type sets = {
  name: string;
  description:string;
  cards: [];
  abbreviation:string;
  image: string;  
}


let _setOpen:any;
let _setData:any;

export default class Sets {

    constructor() {

    }

    static BuildCollection (setOpen:any, setData:any, isadmin: boolean, issuperadmin: boolean, groupname:string) {
      _setOpen = setOpen;
      _setData = setData;
      
      return buildCollection({
        path: "sets",
        exportable:false,
        group: groupname,
        extraActions:Sets.actions,
//                  textSearchEnabled: true,
        schema: Sets.schema(),
        name: "Sets",
        permissions: ({entity,path,user,authController,context}) => {

          return ({
              read: isadmin,
              create: isadmin,
              update: isadmin,
              delete: isadmin
          });
        },
        subcollections: [
          buildCollection({
            name: "Settings",
            path: "public",
            exportable:false,
            schema: Sets.publicSchema(),
            permissions: ({entity,path,user,authController,context}) => {

              return ({
                  read: true,
                  create: issuperadmin, // TODO restrict to one document
                  update: isadmin,
                  delete: isadmin
              });
            },
          }),
          
        ]
      });
    }
    static actions(extraActionParams:ExtraActionsParams) {
      let disabled = (extraActionParams.selectionController.selectedEntities.length == 0) ? true : false;
      const printDeck = (event: React.MouseEvent) => { 
        if (extraActionParams.selectionController.selectedEntities.length == 0) return;
         let printcards = new printCards(extraActionParams.context);  
         printcards.createDecksPDF(extraActionParams.selectionController, true);
      };

      const openModal = async () => {
        let meta = new cardsMeta (extraActionParams.context, _setData);
        await meta.getData(extraActionParams.selectionController, true);
        _setOpen(true);
      }

     return [
         <Button disabled={disabled} onClick={printDeck} color="primary" key="print">
             <PictureAsPdf/>
         </Button>,
          <Button disabled={disabled} onClick={openModal} color="primary" key="meta">
            <Info />
          </Button>,
  
        ];
    }
  
    static schema () {
        return buildSchema<sets>({
          name: "Sets",
          properties: {
            
            name: {
                  title: "Name",
                  dataType: "string"
              },
              cards: {
                title: "Cards",
                dataType: "array",
                of: {
                  dataType: "reference",
                  path: "cards"
                }
  
              },
              description: {
                title: "Description",
                dataType: "string"
            },
           
            abbreviation: {
              title: "Abbreviation",
              dataType: "string"
          },
          
            image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
              title: "Set image",
              dataType: "string",
              config: {
                  storageMeta: {
                      mediaType: "image",
                      storagePath: "sets",
                      acceptedFiles: ["image/*"]
                  }
              }
          }),
              
             
          }
        });
    }

    static publicSchema () {
      return buildSchema({

        name: "Settings",
        
        properties: {
            automaticupload: {
                title: "uploaded from unity",
                dataType: "boolean"
            },
            public: {
              title: "public",
              dataType: "boolean"
            },
            docid: {
              title: "parent document id",
              dataType: "string"
          },
            type: {
              title: "type",
              dataType: "string",
              config: {
                enumValues: {
                  
                    set: "set",
                  
                }
              }
            },
                        
        }
    });
      
  }
    
}