import algoliasearch, { SearchClient, SearchIndex } from "algoliasearch";
import {
    performAlgoliaTextSearch,
    FirestoreTextSearchController
} from "@camberi/firecms";

export default class Search {
    
    client: SearchClient;
    controller : FirestoreTextSearchController | undefined;

    boostersIndex : SearchIndex;
    usersIndex : SearchIndex;
    cardsIndex : SearchIndex;
    
    constructor() {
        this.client = algoliasearch("AKGECOAFEM", "630d5895026e35f317e2d31e1da8bc32");
        this.boostersIndex = this.client.initIndex("boosters");
        this.usersIndex = this.client.initIndex("users");
        this.cardsIndex = this.client.initIndex("cards");
        this.init();
    }


    init () {
        
        

        this.controller =
        ({ path, searchString }) => {
            if (path === "boosters")
                return performAlgoliaTextSearch(this.boostersIndex, searchString);
            if (path === "users")
                return performAlgoliaTextSearch(this.usersIndex, searchString);
            if (path === "cards" || path === "cardsfeedback")
                return performAlgoliaTextSearch(this.cardsIndex, searchString);
            return undefined;
        };
    }

}