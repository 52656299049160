import { SelectionController,DataSource, SnackbarController } from "@camberi/firecms";
import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import Cards from "../collections/cards";
import Config from "./config";

let _datasource : DataSource;
let _snackbar : SnackbarController;
let _fromset:boolean;
let _this:any;


let manadistribution: any[] = [];
let rarity = [
  {id:"Common", amount:0},
  {id:"Uncommon", amount:0},
  {id:"Rare", amount:0},
  {id:"Epic", amount:0},
  {id:"Legendary", amount:0}
];
let uniqueglitchamount = 0;
let uniquesoftwareamount = 0;
let totalGlitches = 0;
let totalSoftware = 0;
let totalcards = 0;
let illustrated = 0;
let cardsFetched: Event;
let cardsNrFetched = 0;
let uniqueCards = 0;
let functional: any[] = [];
let uniqueCardObjects: any[] = [];
let isinpublicsets = 0;

let syndicates = [
  {id: "AdamantHands", amount:0},
  {id: "SentinelsOfEternity", amount:0},
  {id: "CuratorsMaxima", amount:0},
  {id: "SongOfTheChain", amount:0},
  {id: "GuardiansOfTheSource", amount:0},
  {id: "None", amount:0},
]
let subtypes = [
  {id:"None", amount:0},
        {id:"Installation", amount:0},
        {id:"PlugAndPlay", amount:0},
        
        {id:"Samurai", amount:0},
        {id:"Legion", amount:0},
        {id:"Aristocrat", amount:0},
        
        {id:"Creature", amount:0},
        {id:"Shaman", amount:0},
        {id:"Elemental", amount:0},

        {id:"Mecha", amount:0},
        {id:"Punk", amount:0},
        {id:"Scavenger", amount:0},


        {id:"Magus", amount:0},
        {id:"ChaosAgent", amount:0},
        {id:"Fabled", amount:0},

        {id:"Seer", amount:0},
        {id:"Purifier", amount:0},
        {id:"Celestial", amount:0},
];

let headerTitles: any[] = [];
let privateCards: { name: string; }[] = [];


let _data:any;
let _setData: any;
export default class cardsMeta {
    constructor(context: any, setData:any) {
        _setData = setData;
        _datasource = context.dataSource;
        _snackbar = context.snackbarController;
        _this = this;

        cardsFetched = new Event("cardsloaded");
        document.removeEventListener('cardsloaded',this.listener);
        document.addEventListener('cardsloaded',this.listener);
    }

    public async getData (selectionController: SelectionController, fromset:boolean) { 
      _fromset = fromset;
      this.resetData();
        for (var i = 0; i < selectionController.selectedEntities.length; i++) {
            headerTitles.push(selectionController.selectedEntities[i].values.title);
            for (let card of selectionController.selectedEntities[i].values.cards) {
              uniqueCards++;
              await this.fetchcard(card, fromset);
            
              if (!fromset) totalcards +=card.amount;
              if (fromset) totalcards++;
            }
        }
        
    }

    private listener () {
      document.removeEventListener('cardsloaded', this.listener);
      cardsNrFetched = 0;
      _this.dataToModal(_fromset);
      
    }

    private dataToModal (fromset:boolean) {
      let h = (fromset) ? "Set" : headerTitles.toString();
      
      _data = {
        fromset:fromset,
        header: h,
        totalcards: totalcards,
        totalglitches: totalGlitches,
        totalsoftware: totalSoftware,
        uniqueglitches: uniqueglitchamount,
        uniquesoftware: uniquesoftwareamount,
        functional: functional,
        uniqueCardObjects: uniqueCardObjects,
        isinpublicsets: isinpublicsets,
        illustrated: illustrated,
        rarities: [],
        syndicates: [],
        subtypes: [],
        manadistribution: [],
        privateCards:[]
        }
      
        
        
        rarity.map(function(item) {
          _data.rarities.push({"id":item.id, "amount":item.amount});

          //r += "<p><b>" + item.id + ":</b> " + item.amount + "</p>";
        });
        syndicates.map(function(item) {
          if (item.amount != 0 && item.id != "None") _data.syndicates.push({"id":item.id, "amount":item.amount})
        });
        subtypes.map(function(item) {
          if (item.amount != 0) _data.subtypes.push({"id":item.id, "amount":item.amount})
        });

        manadistribution.sort((a,b) => {
          return a.id - b.id;
        })
        manadistribution.map(function(item) {
          if (item.amount != 0) _data.manadistribution.push({"id":item.id, "amount":item.amount, percentage: 100*item.amount / totalcards})
        });

        _data.privateCards.push(privateCards);
      
        
      _setData(_data);


      
    }


    private resetData () {
      headerTitles = [];
      uniqueglitchamount = 0;
      uniquesoftwareamount = 0;
      totalGlitches = 0;
      totalSoftware = 0;
      totalcards = 0;
      uniqueCards = 0;
      illustrated = 0;
      isinpublicsets = 0;
      functional = [];
      manadistribution = [];
      privateCards = [];
      
      uniqueCardObjects = [];

      syndicates = [
        {id: "AdamantHands", amount:0},
        {id: "SentinelsOfEternity", amount:0},
        {id: "CuratorsMaxima", amount:0},
        {id: "SongOfTheChain", amount:0},
        {id: "GuardiansOfTheSource", amount:0},
        {id: "None", amount:0},
      ]

      subtypes = [
        {id:"None", amount:0},
        {id:"Installation", amount:0},
        {id:"PlugAndPlay", amount:0},
        
        {id:"Samurai", amount:0},
        {id:"Legion", amount:0},
        {id:"Aristocrat", amount:0},
        
        {id:"Creature", amount:0},
        {id:"Shaman", amount:0},
        {id:"Elemental", amount:0},

        {id:"Mecha", amount:0},
        {id:"Punk", amount:0},
        {id:"Scavenger", amount:0},


        {id:"Magus", amount:0},
        {id:"ChaosAgent", amount:0},
        {id:"Fabled", amount:0},

        {id:"Seer", amount:0},
        {id:"Purifier", amount:0},
        {id:"Celestial", amount:0},
      ];
      rarity = [
        {id:"Common", amount:0},
        {id:"Uncommon", amount:0},
        {id:"Rare", amount:0},
        {id:"Epic", amount:0},
        {id:"Legendary", amount:0}
      ];
    }



    private async fetchcard(obj: any, fromset:boolean) {
      
      _datasource.fetchEntity({
          path: (fromset) ? obj.path : obj.cards.path,
          entityId: (fromset) ? obj.id : obj.cards.id,
          schema: Cards.schema()
        })
        .then(async (results) => {
          // SET MANA ARRAY
          if (results.values == undefined) {
            console.log ("CARD MISSING VALUES", results);
            cardsNrFetched++;
            return;
          }
          let lookforid = results.values.cost.mana;


          let q = query(collectionGroup(Config.getDevDB(), "public"), where("type", "==", "card"), where("docid", "==", results.id), where("public", "==", false) );
          let snapshot = await getDocs(q);
          let privateCardIDs = [];
          snapshot.forEach((doc) => {
            privateCardIDs.push(doc.data()); 
            if (results.id == doc.data().docid) {
              privateCards.push ( {"name": results.values.name});
              results.values.public = false;
            } else {
              results.values.public = true;
            }
          })
          // if (results.values.public == false) {
          //   privateCards.push ( {"name": results.values.name});
          // }


          
          let checkId = (obj: { id: number; }) => obj.id === lookforid;
          let found = manadistribution.some(checkId);

          if (fromset) {
            if (found) manadistribution.filter( el => {if (lookforid == el.id) el.amount++;})
          if (!found) manadistribution.push( {id:lookforid, amount:1});
          } else {
            if (found) manadistribution.filter( el => {if (lookforid == el.id) el.amount += obj.amount;})
            if (!found) manadistribution.push( {id:lookforid, amount:obj.amount});

          }


          
            uniqueCardObjects.push(results.values);
            functional.push({
              name: results.values.name,
              functional: results.values.functional,
              illustrated: (results.values.image == null) ? false : true
            })
            
            if (results.values.image != null) illustrated++;
            
          
          rarity.filter( el => {
            if (results.values.rarity == el.id) el.amount++;
          })
          
          
          if (results.values.type == "Glitch") {
            uniqueglitchamount++;
            if (fromset) totalGlitches++;
            if (!fromset) totalGlitches += (obj.amount != undefined) ? obj.amount : 1; 
          }  
          if (results.values.type == "Software") {
            uniquesoftwareamount++;
            if (fromset) totalSoftware++;
            if (!fromset) totalSoftware += (obj.amount != undefined) ? obj.amount : 1;
          } 
          subtypes.filter( el => {
            if (results.values.subtype == el.id) el.amount++;
          })
          syndicates.filter( el => {
            if (results.values.syndicate == el.id) el.amount++;
          })
          
          if (cardsNrFetched >= uniqueCards-1) {
            console.log ("CARDS READY");
            document.dispatchEvent(cardsFetched);
          }
          cardsNrFetched++;
        })
          
    }

   
}