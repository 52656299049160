import { buildSchema, buildCollection } from "@camberi/firecms";


export default class Store {

    constructor() {

    }

    static BuildCollection (isadmin: boolean, issuperadmin: boolean, groupname:string) {
      return buildCollection({
        path: "store",
        description: "public texts of the store",
        exportable:false,
        group: groupname,
        schema: Store.schema(),
        name: "Store",
        permissions: ({entity,path,user,authController,context}) => {

          return ({
              read: isadmin,
              create: false,
              update: isadmin,
              delete: isadmin
          });
        },
        
      });
    }
   
  
    static schema () {
        return buildSchema({
          name: "Sets",
          properties: {

            homenews: {
                title: "Homepage: news",
                dataType: "map",
  
                properties: {
                    title: {
                        title: "news title",
                        dataType: "string"
                    },
                    subtitle: {
                        title: "news subtitle",
                        dataType: "string"
                    },
                }
            },

            homecta: {
                title: "Homepage: booster",
                dataType: "map",
  
                properties: {
                    booster: {
                        title: "Booster type",
                        dataType: "string",
                        config: {
                            enumValues: {
                                classic: "Classic",
                                exclusive: "Exclusive",
                                collectors: "Collectors"
                            }
                        }
                    },
                    title: {
                        title: "booster Title",
                        dataType: "string"
                    },
                    description: {
                        title: "booster Description",
                        description: "booster Description",
                        dataType: "string",
                        columnWidth: 300,
                        config: {
                            multiline: true,
                            markdown:true
                            
                        },
                    },
                }
            },


            cardspage: {
                title: "Cards page",
                dataType: "map",
  
                properties: {
                    title: {
                        title: "collection title",
                        dataType: "string"
                    },
                    subtitle: {
                        title: "collection subtitle",
                        dataType: "string"
                    },
                }
            },


            boosterspage: {
                title: "Boosters page",
                dataType: "map",
                properties: {
                    title: {
                        title: "Main title",
                        dataType: "string"
                    },
                    subtitle: {
                        title: "Main subtitle",
                        dataType: "string"
                    },
                    openedtitle: {
                        title: "Pack opened title",
                        dataType:"string"
                    },
                    openedsubtitle: {
                        title: "Pack opened subtitle",
                        dataType:"string"
                    },

                    secretart: {
                        title: "description about the secretart",
                        dataType:"string",
                        config: {
                            markdown:true
                        }
                    },
                    collectorsproducts: {
                        title: "description about the collectors products",
                        dataType:"string",
                        config: {
                            markdown:true
                        }
                    }
                }

            },

            dashboardpage: {
                title: "Dashboard page",
                dataType: "map",
                properties: {
                    title: {
                        title: "Main title",
                        dataType: "string"
                    },
                    subtitle: {
                        title: "Main subtitle",
                        dataType: "string"
                    },
                }
            },


            referralspage: {
                title: "Referrals page",
                dataType: "map",
                properties: {
                    title: {
                        title: "Main title",
                        dataType: "string"
                    },
                    subtitle: {
                        title: "Main subtitle",
                        dataType: "string"
                    },

                    description: {
                        title: "Main description",
                        dataType: "string"
                    },

                    step1: {
                        title: "step #1",
                        dataType: "string"
                    },
                    step2: {
                        title: "step #2",
                        dataType: "string"
                    },
                    step3: {
                        title: "step #3",
                        dataType: "string"
                    },
                    longheadline: {
                        title: "headline below bleed",
                        dataType: "string"
                    },
                    longdescription: {
                        title: "description below bleed",
                        dataType: "string",
                        config: {
                            markdown:true
                        }
                    },
                }
            },

            feedbacks: {
                title: "User feedback",
                dataType: "map",
                properties: {
                    cookiesdisclaimer: {
                        title: "short disclaimer that we use cookies.",
                        dataType: "string",
                        config: {
                            markdown:true
                        }
                    },
                    cookiesfunctional: {
                        title: "short disclaimer about required functional cookies.",
                        dataType: "string",
                    },
                    nowallet: {
                        title: "player isn't connected with a wallet.",
                        dataType: "string"
                    },
                    nocardsincollection: {
                        title: "information that the user doesn't have any cards in their collection.",
                        dataType:"string",
                        config: {
                            markdown:true
                        }
                    },
                    disclaimer: {
                        title: "disclaimer about cards being in develepment.",
                        dataType: "string"
                    },
                    agreeprivacy: {
                        title: "confirmation text that user has read our privacy policy.",
                        dataType:"string",
                        config: {
                            markdown: true
                        }
                    },
                    confirmdelete: {
                        title: "enter password to confirm account deletion.",
                        dataType:"string"
                    },
                    deleteinfo: {
                        title: "delete data request.",
                        dataType: "string"
                    },
                    exchangefunds: {
                        title: "information tooltip to buy eth via credit card",
                        dataType:"string"
                    },
                    addfunds: {
                        title: "information tooltip to deposit funds from your wallet",
                        dataType:"string"
                    },
                    logintopurchase: {
                        title: "information that the user needs to have a wallet connected to purchase",
                        dataType:"string"
                    },
                    toastuserdeleted: {
                        title: "short toast info that the user has been deleted.",
                        dataType: "string"
                    },
                    toastpasswordreset: {
                        title: "short toast info that the password reset email has been sent.",
                        dataType: "string"
                    },
                    packunavailable_duedate: {
                        title: "short info that a booster pack is not available for sale, because the due date has passed.",
                        dataType: "string"
                    },

                    packunavailable_stopped: {
                        title: "short info that a booster pack is not available for sale, because we marked it as such.",
                        dataType: "string"
                    },

                    packunavailable_soldout: {
                        title: "short info that a booster pack is not available for sale, because there is no more availability.",
                        dataType: "string"
                    },


                    referralcodetooltip: {
                        title: "short tooltip about the referral code.",
                        dataType:"string"
                    },
                    referralcodeerror: {
                        title: "short message that the referral code doesn't exist or is corrupt.",
                        dataType:"string"
                    },

                    referralcodeown: {
                        title: "short message that the referral code is the one of the user logged in.",
                        dataType:"string"
                    },
                    referralcodeused: {
                        title: "short message that the referral code has already been used by logged in user.",
                        dataType:"string"
                    },
                    referralcodevalid: {
                        title: "short message that the referral code is valid and will be applied on next purchase.",
                        dataType:"string"
                    },

                    packunavailable_nofunds: {
                        title: "short info that a booster pack is not available for sale, because the user has no funds.",
                        dataType: "string"
                    },

                    packunavailable_presaledate: {
                        title: "short info that a booster pack is not available for sale, because the presale date hasn't passed.",
                        dataType: "string"
                    },

                    pack_onpurchase: {
                        title: "when the user has initiated a pack purchase",
                        dataType: "string"
                    },
                    pack_ontransactioncomplete: {
                        title: "when the transfer is complete and getting random cards",
                        dataType: "string"
                    },

                    pack_onopening: {
                        title: "When the random cards are ready and the pack is being opened",
                        dataType: "string"
                    },

                    pack_oncardsreceived: {
                        title: "When the cards are ready",
                        dataType: "string"
                    },
                    pack_bronzeinfo: {
                        title: "info about the bronze pack",
                        dataType: "string"
                    },
                    pack_silverinfo: {
                        title: "info about the silver pack",
                        dataType: "string"
                    },
                    pack_goldinfo: {
                        title: "info about the gold pack",
                        dataType: "string"
                    },
                    pack_discountinfo: {
                        title: "info about the discount of all packs",
                        dataType: "string"
                    }
                   
                }

            }
            
          }
        });
    }

   
      
  
    
}