import { buildSchema,buildProperty, buildCollection,ExtraActionsParams } from "@camberi/firecms";


export default class rankCategories {

    constructor() {

    }

    static BuildCollection (isadmin:boolean, issuperadmin:boolean, groupname:string) {
      return buildCollection({
        path: "rankCategories",
        group:groupname,
        exportable:false,
        schema: rankCategories.schema(),
        name: "Rank Categories",
        permissions: ({entity,path,user,authController,context}) => { 
            return ({
                read: isadmin,
                create: isadmin,
                update: issuperadmin,
                delete: issuperadmin
            });
        },


      })
    }

   
   
    

    static schema () {
        return buildSchema({
            name: "Rank Categories",
            properties: {
              
              name: {
                title: "Name",
                dataType: "string",
              },

              nameKey: {
                title: "Name Key",
                dataType: "string",
              },

              
             
              ranks: {
                title: "Ranks in Category",
                dataType: "array",
                of: {
                  dataType: "reference",
                  path: "ranks"
                }
  
              },

                            
            }
          });
    }


    
}