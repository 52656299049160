import {  Dialog,  Grid, Hidden, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  } from "@mui/material";
import * as React from 'react';

import ModalPartialCard from './ModalPartialCard';

const portraitTargetPercentage =  15, closeupTargetPercentage = 10, situationalTargetPercentage = 10, placesTargetPercentage = 10, equipementTargetPercentage = 15 ;

export default function detailedModal (props: any, handleClose:any, appBar:any) {
    let maininfo = "total cards: " + props.data.general.totalCards;
    
    maininfo = maininfo.concat("\nfinished cards: " + props.data.general.finishedCards + " (" + Math.round(100*props.data.general.finishedCards/props.data.general.totalCards) + "%)");
    maininfo = maininfo.concat("\nin progress: " + props.data.general.inprogressCards + " (" + Math.round(100*props.data.general.inprogressCards/props.data.general.totalCards) + "%)");
    maininfo = maininfo.concat("\nto be revised: " + props.data.general.toberevised + " (" + Math.round(100*props.data.general.toberevised/props.data.general.totalCards) + "%)");
    maininfo = maininfo.concat("\n\n");
    maininfo = (props.data.priority.urgenttotal > 0) ? maininfo.concat("\nurgent priority: " + props.data.priority.urgenttotal + " (" + props.data.priority.urgentinprogress + " in progress, " + props.data.priority.urgentdone + " done)") : maininfo.concat("");
    maininfo = (props.data.priority.hightotal > 0) ? maininfo.concat("\n high priority: " + props.data.priority.hightotal + " (" + props.data.priority.highinprogress + " in progress, " + props.data.priority.highdone + " done)") : maininfo.concat("");


    let unfinishedCards = props.data.general.totalCards - props.data.general.finishedCards - props.data.general.inprogressCards;
    let publicinfo = "\nPublished: " + props.data.general.public;
    publicinfo = publicinfo.concat("\nFinished (not published): " + (props.data.general.finishedCards-props.data.general.public));
    
    let secondaryinfo = "Glitches: " + props.data.general.glitch + " (" + Math.round(100*props.data.general.glitch/props.data.general.totalCards) + "%)";
    secondaryinfo = secondaryinfo.concat("\nSoftware: " + props.data.general.software  + " (" + Math.round(100*props.data.general.software/props.data.general.totalCards) + "%)");
    secondaryinfo = secondaryinfo.concat("\n-----");
   
   
    let highlightPortraitStyling =  "";
    if (!between(Math.round(100*props.data.type.portrait/props.data.general.totalCards), Number(portraitTargetPercentage)-2, Number(portraitTargetPercentage)+2)) highlightPortraitStyling = "style='color:red'";
    secondaryinfo = secondaryinfo.concat("\n<span " + highlightPortraitStyling +">Portraits: " + props.data.type.portrait + " (" + Math.round(100*props.data.type.portrait/props.data.general.totalCards) + "%)</span>");
  

    let highlightSituationalStyling =  "";
    if (!between(Math.round(100*props.data.type.situational/props.data.general.totalCards), Number(situationalTargetPercentage)-2, Number(situationalTargetPercentage)+2)) highlightSituationalStyling = "style='color:red'";
    secondaryinfo = secondaryinfo.concat("\n<span " + highlightSituationalStyling +">Situational: " + props.data.type.situational + " (" + Math.round(100*props.data.type.situational/props.data.general.totalCards) + "%)</span>");

    let highlightCloseupStyling =  "";
    if (!between(Math.round(100*props.data.type.closeup_action/props.data.general.totalCards), Number(closeupTargetPercentage)-2, Number(closeupTargetPercentage)+2)) highlightCloseupStyling = "style='color:red'";
    secondaryinfo = secondaryinfo.concat("\n<span " + highlightCloseupStyling +">Close-up Action: " + props.data.type.closeup_action + " (" + Math.round(100*props.data.type.closeup_action/props.data.general.totalCards) + "%)</span>");
    
    let highlightPlacesStyling =  "";
    if (!between(Math.round(100*props.data.type.places/props.data.general.totalCards), Number(placesTargetPercentage)-2, Number(placesTargetPercentage)+2)) highlightPlacesStyling = "style='color:red'";
    secondaryinfo = secondaryinfo.concat("\n<span " + highlightPlacesStyling +">Places: " + props.data.type.places + " (" + Math.round(100*props.data.type.places/props.data.general.totalCards) + "%)</span>");
    
    
    let highlightEquipmentStyling =  "";
    if (!between(Math.round(100*props.data.type.equipment/props.data.general.totalCards), Number(equipementTargetPercentage)-2, Number(equipementTargetPercentage)+2)) highlightEquipmentStyling = "style='color:red'";
    secondaryinfo = secondaryinfo.concat("\n<span " + highlightEquipmentStyling +">Equipment: " + props.data.type.equipment + " (" + Math.round(100*props.data.type.equipment/props.data.general.totalCards) + "%)</span>");
    
    secondaryinfo = secondaryinfo.concat("\nFull body character: " + props.data.type.fullbody + " (" + Math.round(100*props.data.type.fullbody/props.data.general.totalCards) + "%)");

    secondaryinfo = secondaryinfo.concat("\nScifi themed: " + props.data.scifi);
    
    
  
    let syndicateinfo = "";
    let syndicatevalues:any = [];
    let syndicatelabels:any = [];
    for (const [key, value] of Object.entries(props.data.syndicates)) {  
      let v:any = value;
      syndicateinfo += "\n"+key.replace(/([A-Z])/g, ' $1').trim() + ": " + value + " (" + Math.round(100*v/props.data.general.glitch) + "%)" ;
      syndicatelabels.push(key.replace(/([A-Z])/g, ' $1').trim());
      syndicatevalues.push(value);
    }
  
    let genderinfo = "";
    let gendervalues:any = [];
    let genderlabels:any = [];
    for (const [key, value] of Object.entries(props.data.gender)) {  
      let v:any = value;
      genderinfo += "\n"+key.replace(/([A-Z])/g, ' $1').trim() + ": " + value + " (" + Math.round(100*v/props.data.general.glitch) + "%)" ;
      genderlabels.push(key.replace(/([A-Z])/g, ' $1').trim());
      gendervalues.push(value);
    }
    let ethnicityinfo = "";
    let ethnicityvalues:any = [];
    let ethnicitylabels:any = [];
    for (const [key, value] of Object.entries(props.data.ethnicity)) {  
      let v:any = value;
      ethnicityinfo += "\n"+key.replace(/([A-Z])/g, ' $1').trim() + ": " + value + " (" + Math.round(100*v/props.data.general.glitch) + "%)" ;;
      ethnicitylabels.push(key.replace(/([A-Z])/g, ' $1').trim());
      ethnicityvalues.push(value);
    }


    
    return(
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}>
        
  
        {appBar(props, handleClose)}
        <Hidden/>
        
        <Grid container spacing={2} display="flex" sx={{m:2}}>          
          { ModalPartialCard("All illustrations", maininfo, ["finished", "in progress", "to be revised", "unfinished"], [props.data.general.finishedCards, props.data.general.inprogressCards, props.data.general.toberevised, unfinishedCards]) }
          {/* { ModalPartialCard("Public illustrations", publicinfo, ["public", "remaining"], [props.data.general.public, props.data.general.finishedCards-props.data.general.public]) } */}
          { ModalPartialCard("Types", secondaryinfo, [
                                              "Portrait (" + Math.round(100*props.data.type.portrait/props.data.general.totalCards) + "% / " + portraitTargetPercentage +"%)", 
                                              "Situational (" + Math.round(100*props.data.type.situational/props.data.general.totalCards) + "% / " + situationalTargetPercentage +"%)", 
                                              "Close-Up (" + Math.round(100*props.data.type.closeup_action/props.data.general.totalCards) + "% / " + closeupTargetPercentage +"%)", 
                                              "Places (" + Math.round(100*props.data.type.places/props.data.general.totalCards) + "% / " + placesTargetPercentage +"%)", 
                                              "Equipment (" + Math.round(100*props.data.type.equipment/props.data.general.totalCards) + "% / " + equipementTargetPercentage +"%)",
                                               "Fullbody character"], [props.data.type.portrait, props.data.type.situational, props.data.type.closeup_action, props.data.type.places, props.data.type.equipment, props.data.type.fullbody]) }
          { ModalPartialCard("Syndicates", syndicateinfo, syndicatelabels, syndicatevalues) }
          { ModalPartialCard("Gender", genderinfo, genderlabels, gendervalues) }
          { ModalPartialCard("Ethnicity", ethnicityinfo, ethnicitylabels, ethnicityvalues) }
          
        </Grid>
        <Grid sx={{width:500, margin:"0 auto"}} >          
          { createArtistsTable(props.data.artists) }
        </Grid>
      </Dialog>
   )
  }


  const createArtistsTable = (object:any) => {
    return (
      <TableContainer >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Artist</TableCell>
              <TableCell align="center">done</TableCell>
              <TableCell align="center">in progress</TableCell>
              <TableCell align="center">to be revised</TableCell>
              <TableCell align="center">not uploaded</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
            {Object.keys(object).map((key:any) => (
              
              <TableRow
                key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{key}</TableCell>
                <TableCell align="center">{object[key].done}</TableCell>
                <TableCell align="center">{object[key].inprogress}</TableCell>
                <TableCell align="center">{object[key].toberevised}</TableCell>
              </TableRow>
           ))}
          </TableBody>
        </Table>
      </TableContainer> 
   )
  
  }
function between(x:Number, min:Number, max:Number) {
    return x >= min && x <= max;
  }