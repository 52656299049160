import { buildSchema,buildProperty, buildCollection } from "@camberi/firecms";


export default class Seasons {

    constructor() {

    }

    static BuildCollection (isadmin:boolean, issuperadmin:boolean, groupname:string) {
      return buildCollection({
        path: "seasons",
        group:groupname,
        exportable:false,
//                  textSearchEnabled: true,
        schema: Seasons.schema(),
        name: "Seasons",
        permissions: ({entity,path,user,authController,context}) => { 
            return ({
                read: isadmin,
                create: false,
                update: issuperadmin,
                delete: issuperadmin
            });
        },



      })
    }

    static schema () {
        return buildSchema({
            name: "Seasons",
            properties: {
              nameKey: {
                title: "Name",
                dataType: "string"
              },
              descriptionKey: {
                title: "description",
                dataType: "string"
              },
              startDate: {
                title: "season availability starts",
                dataType: "timestamp"
              },
              endDate: {
                title: "season availability ends",
                dataType: "timestamp"
              },
              public: {
                title: "public",
                dataType: "boolean"
              },    
                
            }
          });
    }


    
}