import {  Dialog, Grid, IconButton, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import * as React from 'react';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';


export default function modalPartialLOSTSentResult (props:any, handleClose:any, appBar:any) {
    return(
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}>
        
  
        {appBar(props, handleClose)}
        
        <Grid container spacing={2} display="flex" sx={{m:2}}>          
          
          
        </Grid>
        <Grid container spacing={2} display="flex">          
          { createTable( props.data.response) }
        </Grid>
      </Dialog>
    )
  }
  

  const createTable = (response:any) => {
    
    return (
      <TableContainer sx={{m:4}} component={Paper} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>type</TableCell>
              <TableCell align="left">title</TableCell>
              <TableCell align="left">message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
            {response.map((item:any,i:any) => (
              
              
              <TableRow
                key={i}
                sx={{ background: (item.type == "error") ? "#f44336" : "auto", '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">
                <Typography  >{item.type}</Typography>
                </TableCell> 
                <TableCell align="left">
                  <Typography  >{item.title}</Typography>
                </TableCell>
                  
                <TableCell align="left"> 
                <Typography  >{item.message}</Typography>
                </TableCell>
                
              </TableRow>
            
            ))}
          </TableBody>
        </Table>
      </TableContainer> 
    )
  
  }