import { buildSchema,buildProperty, buildCollection,ExtraActionsParams } from "@camberi/firecms";


export default class Ranks {

    constructor() {

    }

    static BuildCollection (isadmin:boolean, issuperadmin:boolean, groupname:string) {
      return buildCollection({
        path: "ranks",
        group:groupname,
        exportable:false,
        schema: Ranks.schema(),
        name: "Ranks",
        permissions: ({entity,path,user,authController,context}) => { 
            return ({
                read: isadmin,
                create: isadmin,
                update: issuperadmin,
                delete: issuperadmin
            });
        },


      })
    }

   
   
    

    static schema () {
        return buildSchema({
            name: "Rank",
            properties: {
              
              nameKey: {
                title: "Name Key",
                dataType: "string",
              },
              name: {
                title: "Name",
                dataType: "string",
              },
              rankIcon: buildProperty({ // The `buildProperty` method is an utility function used for type checking
                title: "Rank Icon (1024x1024)",
                dataType: "string",
                config: {
                    storageMeta: {
                        mediaType: "image",
                        storagePath: "ranks",
                        maxSize:1000000,
                        acceptedFiles: ["image/*"]
                        
                    }
                }
              }),

              requirement: {
                title: "Required Points to unlock",
                dataType: "number"
              },

              losePoints: {
                title: "Lose Points",
                dataType: "number"
              },
              winPoints: {
                title: "Win Points",
                dataType: "number"
              },
              
              rewards: {
                dataType: "array",
                of : {
                  dataType:"map",
                  properties: {
                    type:  buildProperty({ // The `buildProperty` method is an utility function used for type checking
                      title: "Reward type",
                      dataType: "string",
                      config: {
                        enumValues: {
                          XP: "Skill points",
                          Xsolla: "Xsolla Item",
                          XsollaCurrency: "Xsolla Currency",
                          defaultdeck: "default deck"
                       }
                      }
                    }),
                    rewardSKU: {
                      title: "Reward SKU (if Xsolla) or ID (if firestore)",
                      dataType: "string",
                    },
  
                    amount: {
                      title: "Reward Amount",
                      dataType: "number",
                    }
                  }
                }
              },                
            }
          });
    }


    
}