import { buildSchema,buildProperty, buildCollection,ExtraActionsParams } from "@camberi/firecms";
import { FileUploader } from "react-drag-drop-files";
import Papa from "papaparse";
import { v4 as uuidv4 } from 'uuid';
import { fetchSignInMethodsForEmail, sendPasswordResetEmail } from "firebase/auth";
import Config from "../utils/config";
import { collection, doc, setDoc } from "firebase/firestore";





export default class Quests {

    constructor() {

    }

    static BuildCollection (isadmin:boolean, issuperadmin:boolean, groupname:string) {
      return buildCollection({
        path: "quests",
        group:groupname,
        exportable:false,
        schema: Quests.schema(),
        name: "Challenges & Rewards",
        permissions: ({entity,path,user,authController,context}) => { 
            return ({
                read: isadmin,
                create: isadmin,
                update: issuperadmin,
                delete: issuperadmin
            });
        },

        
        // subcollections: [
        //   buildCollection({
        //     name: "locale",
        //     path: "public",
        //     exportable:false,
        //     schema: Quests.localeSchema(),
        //   }),
          
        // ]
      })
    }
/*
    static localeSchema () {
      return buildSchema({ 
        name: "Locales",
        properties: {
            locale: {
              title: "Locale",
              dataType: "string",
              config: {
                enumValues: {
                    de: "de",
                    gr: "gr"
                }
            }

            },
            name: {
              title: "name",
              dataType: "string",
            },  
            description: {
              title: "description",
              dataType: "string",
            },  
          
                        
        }
      });  
    }
  */ 
    

    static schema () {
        return buildSchema({
            name: "Challenges & Rewards",
            properties: {
              
              name: {
                title: "Name",
                dataType: "string"
              },
              description: {
                title: "Description",
                dataType: "string"
              },
              rewardText: {
                title: "Reward text",
                dataType: "string"
              },
              image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
                title: "Quest Icon (1024x1024)",
                dataType: "string",
                config: {
                    storageMeta: {
                        mediaType: "image",
                        storagePath: "quests",
                        maxSize:1000000,
                        acceptedFiles: ["image/*"]
                        
                    }
                }
              }),
              rewardType: {
                title: "Reward Type",
                dataType: "string",
                config: {
                  enumValues: {
                      XP: "Skill points",
                      Xsolla: "Xsolla Item",
                      XsollaCurrency: "Xsolla Currency",
                      defaultdeck: "default deck"
                  }
                }
              },
              rewardSKU: {
                title: "Reward SKU (if Xsolla) or ID (if firestore)",
                dataType: "string",
              },

              rewardAmount: {
                title: "Reward Amount",
                dataType: "number"
              },

              duration: {
                title: "Duration",
                dataType: "string",
                config: {
                  enumValues: {
                    Daily: "Daily",  
                    Weekly: "Weekly",
                    Monthly: "Monthly"
                  }
                }
              },

              
              action: {
                title: "Action",
                dataType: "string",
                config: {
                  enumValues: {
                    PlayGlitch: "Play Glitch",  
                    PlaySoftware: "Play Software",
                    KillGlitch: "Kill Glitch",
                    UseSkill: "Use Skill",
                    PlayMatch: "Play Match",
                    WinMatch: "Win Match",
                    Login: "Login",
                  }
                }
              },
              amountOfAction: {
                title: "Amount of Action",
                dataType: "number"
              },

              
              
            
                
            }
          });
    }


    
}