import { buildSchema,buildProperty, buildCollection,ExtraActionsParams } from "@camberi/firecms";
import { FileUploader } from "react-drag-drop-files";
import Papa from "papaparse";
import { v4 as uuidv4 } from 'uuid';
import { fetchSignInMethodsForEmail, sendPasswordResetEmail } from "firebase/auth";
import Config from "../utils/config";
import { collection, doc, setDoc } from "firebase/firestore";

type users = {
 //   characters: [];
    decks:[] ;
    cards:[] ;
    //roles:[];
    name: string;
    
    profileimage: string;
    lostTokens:number;
    availableskillpoints:number;
    // email:string;
    // walletid: string;
  }



export default class Users {

    constructor() {

    }

    static BuildCollection (isadmin:boolean, issuperadmin:boolean, groupname:string) {
      return buildCollection({
        path: "users",
        group:groupname,
        exportable:false,
        extraActions: Users.actions,
        schema: Users.schema(),
        name: "Users",
        permissions: ({entity,path,user,authController,context}) => { 
            return ({
                read: isadmin,
                create: isadmin,
                update: issuperadmin,
                delete: issuperadmin
            });
        },


        subcollections: [
          buildCollection({
            name: "Settings",
            path: "public",
            exportable:false,
            schema: Users.publicSchema(),
            permissions: ({entity,path,user,authController,context}) => {

              return ({
                  read: true,
                  create: issuperadmin,
                  update: issuperadmin,
                  delete: issuperadmin
              });
            },
          }),

          buildCollection({
            name: "Private data",
            path: "private",
            exportable:false,
            schema: Users.privateSchema(),
            permissions: ({entity,path,user,authController,context}) => {

              return ({
                  read: isadmin,
                  create: issuperadmin,
                  update: issuperadmin,
                  delete: issuperadmin
              });
            },
          }),
          
        ]

      })
    }


    static actions(extraActionParams:ExtraActionsParams) {
    
      const importusers = (file:any) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async function (results:any) {
              console.log(results.data);
              

              let userDocs:any = [];            
              //results.data.forEach (async (item: any) => {
              for (let item of results.data) {
               
                // check if the email is in
                if (item.email == "" || item.email == undefined) {
                  extraActionParams.context.snackbarController.open({type: "error", title: "Missing email",message: "user with name: " + item.displayName + " is missing an email"})
                  continue;
                }
                console.log ("notify: " + item.email);
                item.emailVerified = true;
                item.customClaims = { admin: false, superadmin:false, tester:true };
                item.uid = uuidv4();
                userDocs.push(item);
              };

              const requestOptions = {method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ data: userDocs}) };
              try {
                fetch('https://europe-west3-tlg-dev.cloudfunctions.net/importUsers', requestOptions)
                  .then(response => response.json())
                  .then( (res) => {
                    console.log (res);                    
                  } );
              } catch (e:any) {
                console.log (e);
              }
              
            },
        });
      };
      


      

      return [
        
        <FileUploader
          key="usercsv"
          multiple={false}
          label="import testers."
          handleChange={importusers}
          name="file"
          types={["CSV"]}
        />,


          ];
    }

    static schema () {
        return buildSchema<users>({
            name: "Users",
            properties: {
              name: {
                title: "Name",
                dataType: "string"
              },
              
              lostTokens: {
                title: "LOST",
                dataType: "number",
                
              },
              availableskillpoints: {
                title: "available skill points",
                dataType: "number",
                
              },
            
                // characters: {
                //   title: "Characters",
                //   dataType: "array",
                //   of: {
                //     dataType: "reference",
                //     path: "characters"
                //   }
                // },
                decks: {
                  title: "Decks",
                  dataType: "array",
                  of: {
                    dataType: "reference",
                    path: "decks",
                    previewProperties: ["title"]
                  }
                },

                cards: {
                  title: "cards",
                  dataType: "array",
                  of: {
                    dataType:"map",
                    properties: {
                      cards: {
                        title: "Card",
                        dataType: "reference",
                        path: "cards",
                        previewProperties: ["title"],
                      },
                      amount: {
                        title: "Amount",
                        dataType: "number",
                      },
                      edition: {
                        title: "edition",
                        dataType: "string",
                        config: {
                          enumValues: {
                              classic: "classic",
                              exclusive: "exclusive",
                              collectors: "collectors",
                          }
                        }
                      },  
                    }
                  } 
                },
               
                
               
                profileimage: buildProperty({ // The `buildProperty` method is an utility function used for type checking
                    title: "Profile image",
                    dataType: "string",
                    config: {
                        storageMeta: {
                            mediaType: "image",
                            storagePath: "users",
                            acceptedFiles: ["image/*"]
                        }
                    }
                }),
                
            }
          });
    }


    static publicSchema () {
      return buildSchema({

        name: "Settings",
        
        properties: {
          roles: {
            title: "Roles",
            dataType: "array",
            of: {
              dataType: "string",
              config: {
                enumValues: {
                    anonymous: "anonymous",
                    user: "user",
                    tester: "tester",
                    admin: "admin",
                    superadmin: "superadmin",
                    
                }
              }
              
            }
          },
          docid: {
            title: "parent document id",
            dataType: "string"
        },
            public: {
              title: "public",
              dataType: "boolean"
          },
          type: {
            title: "type",
            dataType: "string",
            config: {
              enumValues: {
                 
                  user: "user",
              }
            }
          },
                        
        }
    });
      
  }

  static privateSchema () {
    return buildSchema({

      name: "Private Info",
      
      properties: {
        walletid: {
          title: "Wallet ID",
          dataType: "string"
        },
        email: {
          title: "E-Mail",
          dataType: "string"
      },
                      
      }
  });
    
}

    
}