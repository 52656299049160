
import * as React from 'react';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import Info from '@mui/icons-material/Info';
import { Button, Tooltip } from "@mui/material";
import {ExtraActionsParams,buildSchema, buildCollection, buildProperty} from "@camberi/firecms";



import printCards from "../utils/printCards";
import cardsMeta from "../utils/cardsMeta";



type decks = {
    title:string;
    description : string;
    remnant:string;
    playstyle : string;
    speed:string;
    image:string;
    comments: string;

    cards: [];
    syndicate:string,
    // public:boolean,
  }
  let _setOpen:any;
  let _setData:any;
export default class defaultDecks {

    
    static BuildCollection(setOpen:any, setData:any, isadmin:boolean, issuperadmin:boolean, groupname:string) {
      _setOpen = setOpen;
      _setData = setData;

      return buildCollection({
        path:"defaultdecks",
        exportable: false,
        group:groupname,
        schema:defaultDecks.schema(),
        extraActions: defaultDecks.actions,
        name: "Default decks",
        permissions: ({entity, path, user, authController, context }) => {
          return ({
              read: true,
              create: isadmin,
              update: isadmin,
              delete: isadmin
          });
        },
        


        subcollections: [
          buildCollection({
            name: "Settings",
            path: "public",
            exportable:false,
            schema: defaultDecks.publicSchema(),
            callbacks: {
              onPreSave: ({values, context}) => {
                
                  if (values != undefined) {
                    let msg ="";
                    if (values.type != "defaultdeck") msg += "Assign the 'defaultdeck' to the type.";
                    if (values.docid == "" || values.docid == undefined) msg += "Assign the parent document id to its field."
                    
                    let snackbar = context.snackbarController;
                    snackbar.open({
                      type: (msg == "") ? "success" : "error",
                      title: "Settings",
                      message: (msg == "") ? "settings are correct." : msg
                    });
                  }
              return values;
              }
            },
            permissions: ({entity,path,user,authController,context}) => {
              return ({
                  read: true,
                  create: issuperadmin, // TODO allow to create only one document
                  update: issuperadmin,
                  delete: issuperadmin
              });
            },
          }),
          
        ]
      });
    }

    static actions(extraActionParams:ExtraActionsParams) {
      let disabled = (extraActionParams.selectionController.selectedEntities.length == 0) ? true : false;
         
      const printDeck = (event: React.MouseEvent) => { 
            if (extraActionParams.selectionController.selectedEntities.length == 0) return;
             let printcards = new printCards(extraActionParams.context);  
             printcards.createDecksPDF(extraActionParams.selectionController, false);
         };
      
        const openModal = async () => {
          let meta = new cardsMeta (extraActionParams.context, _setData);
          await meta.getData(extraActionParams.selectionController, false);
          _setOpen(true);
        }

         return [
            <Tooltip title="create a pdf of the selected deck" key="print-tip">
                <Button disabled={disabled} onClick={printDeck} color="primary" key="print">
                    <PictureAsPdf/>
                </Button>
             </Tooltip>,
             <Tooltip title="get info of the selected deck" key="meta-tip">
              <Button disabled={disabled} onClick={openModal} color="primary" key="meta">
                <Info />
              </Button>
              </Tooltip>
            ];
       
    }


    static schema () {
        return buildSchema<decks>({
          name: "Deck",
          
          properties: {
          
            
            cards: {
              title: "Cards",
              dataType: "array",
              columnWidth: 500,
              validation: {
                max:30
              },
              of: {
                dataType:"map",
                properties: {
                  cards: {
                    title: "Cards",
                    dataType: "reference",
                    path: "cards",
                    previewProperties: ["name", "type", "cost"],
                    
                  },
                 
                amount: {
                      title: "Amount",
                      dataType: "number",
                      validation: {
                        min: 1,
                        max: 3
                    },
                  }
                  
                }
              } 
            

            },
           
            title: {
              title: "Title",
              dataType: "string"
            },
            description: {
              title: "Description",
              dataType: "string",
              config: {
                multiline: true
              }
            },
            remnant: {
              title: "Remnant ID",
              dataType: "string"
            },

            playstyle: {
              title: "Playstyle",
              dataType: "string",
            },
            speed: {
              title: "Speed",
              dataType: "string",
              config: {
                enumValues: {
                    "early game": "Early Game",
                    "mid game": "Mid Game",
                    "late game": "Late Game",
                }
              }
            },
            image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
              title: "Preview Image",
              dataType: "string",

              config: {
                  storageMeta: {
                      mediaType: "image",
                      storagePath: "deckpreviewimages",
                      acceptedFiles: ["image/*"],
                      maxSize:1000000,
                      metadata: {
                        customMetadata: {
                          'visibility': 'admin'
                        }
                    }
                      
                  }
              }
            }),
            comments: {
              title: "Comments",
              dataType: "string",
              config: {
                multiline: true
            }
            },

            syndicate: {
              title: "Syndicate",
              dataType: "string",
              config: {
                enumValues: {
                    AdamantHands: "Adamant Hands",
                    SentinelsOfEternity: "Sentinels of Eternity",
                    CuratorsMaxima: "Curators Maxima",
                    SongOfTheChain: "Song of the Chain",
                    GuardiansOfTheSource: "Guardians of the Source",
                    None: "None",
                }
              }
            },
         

          },
        });
    }


    static publicSchema () {
      return buildSchema({
        
        name: "Settings",
        properties: {
            public: {
                title: "public",
                dataType: "boolean"
            },
            docid: {
              title: "parent document id",
              dataType: "string"
          },
            type: {
              title: "type",
              dataType: "string",
              config: {
                enumValues: {
                    
                    defaultdeck: "default deck",
                    
                }
              }
            },
        }
      });
    }
}
