// Replace with your Firebase config
export const firebaseConfig = {
    apiKey: "AIzaSyA2BRzENUBPIGakgr9HQzFecwAhSlC9EwA",
    authDomain: "tlg-dev.firebaseapp.com",
    projectId: "tlg-dev",
    storageBucket: "tlg-dev.appspot.com",
    messagingSenderId: "994854787733",
    appId: "1:994854787733:web:f8b71a9c6ff2ca0bdd199c",
    measurementId: "G-FY3VFGBQ59"
};


// Replace with your Firebase config
export const firebaseLiveConfig = {
    apiKey: "AIzaSyAFBwHlcamrRVzo5Sb4MYKg-UPnnpJf8k0",
    authDomain: "tlg-live.firebaseapp.com",
    projectId: "tlg-live",
    storageBucket: "tlg-live.appspot.com",
    messagingSenderId: "849132045843",
    appId: "1:849132045843:web:448b4446553f15ce74a1e6",
    measurementId: "G-DG8WDVFF2V"
};
