import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, setDoc } from "firebase/firestore";
import { sendEmailVerification } from "firebase/auth";
import config from "./config";


let _user:any, _authcontroller:any;


export default class CustomAuthenticator {

    constructor(user:any, authController:any) {
        _user = user;
        _authcontroller = authController;    

        // send email verification if the user is not verified
        if (_user.emailVerified == false) {
            sendEmailVerification(_user);
        }
    }





    async getUserFromFirestore () {
        let userdocid = String(_user?.uid);
        let docRef = doc(config.getDevDB(), "users", userdocid);
        let docSnap = await getDoc(docRef);
        let response; 

        if (docSnap.exists()) {
            response = this.authenticateUser(docSnap);
        } else {
            response = this.createUser();
        }
        return response;
        
    }


    async authenticateUser (docSnap:any) {
        let publicSubcolRef = collection(doc(config.getDevDB(), "users", String(_user?.uid)), "public");
        let sub = await getDocs(publicSubcolRef);
        let allow = false;
        sub.forEach ( (doc) => {
            if (doc.data().roles.includes("admin") || doc.data().roles.includes("superadmin")) {      
                allow = true;
                // if they are not an admin, don't let them in
            } else {
                allow = false;
            }
        })
        return allow;
        
    }

    async createUser () {
        let docRef = doc(config.getDevDB(), "users", String(_user?.uid));
       
        await setDoc(docRef, {
            //characters: [],
            decks: [],
            name: _user?.displayName,
            profileimage: _user?.photoURL,
        });

        
        return false;
    }
}