
import * as React from 'react';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import Info from '@mui/icons-material/Info';
import { Button, Tooltip } from "@mui/material";
import {ExtraActionsParams,buildSchema, buildCollection, buildProperty} from "@camberi/firecms";



import printCards from "../utils/printCards";
import cardsMeta from "../utils/cardsMeta";





let _setOpen:any;
let _setData:any;
export default class Decks {

    
    static BuildCollection(setOpen:any, setData:any, isadmin:boolean, issuperadmin:boolean, groupname:string) {
      _setOpen = setOpen;
      _setData = setData;
      return buildCollection({
        path:"decks",
        exportable: false,
        group:groupname,
        schema:Decks.schema(),
        extraActions: Decks.actions,
        name: "User decks",
        permissions: ({entity, path, user, authController, context }) => {
          return ({
              read: true,
              create: isadmin,
              update: isadmin,
              delete: isadmin
          });
        },


        subcollections: [
          buildCollection({
            name: "Settings",
            path: "public",
            exportable:false,
            schema: Decks.publicSchema(),
            permissions: ({entity,path,user,authController,context}) => {
              return ({
                  read: true,
                  create: isadmin, // TODO allow to create only one document
                  update: isadmin,
                  delete: issuperadmin
              });
            },
          }),
          
        ]
      });
    }

    static actions(extraActionParams:ExtraActionsParams) {
      let disabled = (extraActionParams.selectionController.selectedEntities.length == 0) ? true : false;
        const printDeck = (event: React.MouseEvent) => { 
          if (extraActionParams.selectionController.selectedEntities.length == 0) return;
            let printcards = new printCards(extraActionParams.context);  
            printcards.createDecksPDF(extraActionParams.selectionController, false);
        };
        
        
        
        
        const openModal = async () => {
          let meta = new cardsMeta (extraActionParams.context, _setData);
          await meta.getData(extraActionParams.selectionController, false);
          _setOpen(true);
        }


         return [
            <Tooltip title="create a pdf of the selected deck" key="print-tip">
                <Button disabled={disabled} onClick={printDeck} color="primary" key="print">
                    <PictureAsPdf/>
                </Button>
             </Tooltip>,
             <Tooltip title="get info of the selected deck" key="meta-tip">
              <Button disabled={disabled} onClick={openModal} color="primary" key="meta">
                <Info />
              </Button>
              </Tooltip>
            ];
       
    }


    static schema () {
        return buildSchema({
          name: "Deck",
          
          properties: {
            
            author: {
              title: "author",
              dataType: "reference",
              path: "users",
              previewProperties: ["name"],
            },

            title: {
              title: "Title",
              dataType: "string"
            },

            description: {
              title: "Description",
              dataType: "string"
            },

            image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
              title: "Preview Image",
              dataType: "string",

              config: {
                  storageMeta: {
                      mediaType: "image",
                      storagePath: "deckpreviewimages",
                      acceptedFiles: ["image/*"],
                      maxSize:1000000,
                      metadata: {
                        customMetadata: {
                          'visibility': 'admin'
                        }
                    }
                      
                  }
              }
            }),

            remnant: {
              title: "Remnant ID",
              dataType: "string"
            },
            
            cards: {
              title: "Cards",
              dataType: "array",
              columnWidth: 500,
              validation: {
                max:30
              },
              of: {
                dataType:"map",
                properties: {
                  cards: {
                    title: "Cards",
                    dataType: "reference",
                    path: "cards",
                    previewProperties: ["name", "type", "cost"],
                    
                  },
                 
                amount: {
                      title: "Amount",
                      dataType: "number",
                      validation: {
                        min: 1,
                        max: 3
                    },
                  }
                  
                }
              } 
            

            },


           
           
        

          

          },
        });
    }


    static publicSchema () {
      return buildSchema({
        
        name: "Settings",
        properties: {
            public: {
                title: "public",
                dataType: "boolean"
            },    
            docid: {
              title: "parent document id",
              dataType: "string"
          },
            type: {
              title: "type",
              dataType: "string",
              config: {
                enumValues: {
                   
                    deck: "user deck",
                   
                }
              }
            },
        }
      });
    }
}
