import { buildSchema,buildProperty, buildCollection } from "@camberi/firecms";


export default class Settings {

    constructor() {

    }

    static BuildCollection (isadmin:boolean, issuperadmin:boolean, groupname:string) {
      return buildCollection({
        path: "settings",
        group:groupname,
        exportable:false,
//                  textSearchEnabled: true,
        schema: Settings.schema(),
        name: "Game Settings",
        permissions: ({entity,path,user,authController,context}) => { 
            return ({
                read: isadmin,
                create: false,
                update: issuperadmin,
                delete: issuperadmin
            });
        },



      })
    }

    static schema () {
        return buildSchema({
            name: "Settings",
            properties: {
              available: {
                title: "available for testing",
                dataType: "boolean"
              },
              allowregistration: {
                title: "allow registration",
                dataType: "boolean"
              }, 
              whitelisteddecks: {
                title: "Whitelisted decks to give outside of rewards",
                dataType: "array",
                of: {
                  dataType: "reference",
                  path: "defaultdecks"
                }
              },    
              accesscode: {
                title: "accesscode",
                dataType: "string"
              }, 
              dailyQuestCount: {
                title: "daily quest count",
                dataType: "number"
              },    
              weeklyQuestCount: {
                title: "weekly quest count",
                dataType: "number"
              },    
              monthlyQuestCount: {
                title: "monthly quest count",
                dataType: "number"
              },    
            }
          });
    }


    
}