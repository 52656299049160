import { Alert, AppBar, Dialog, Grid, Hidden, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { Box } from "@mui/system";
import { styled } from '@mui/material/styles';


import  { tableCellClasses } from '@mui/material/TableCell';

import Paper from '@mui/material/Paper';

import ModalPartialCard from './ModalPartialCard';
import { Error, Warning } from "@mui/icons-material";



const GlitchTargetPercentage =  "65", SoftwareTargetPercentage =  "35",
      CommonTargetPercentage = "30", UncommonTargetPercentage = "26", RareTargetPercentage = "22", EpicTargetPercentage = "14", LegendaryTargetPercentage = "8",
      SyndicateTargetPercentage = "20",
      TransformTargetPercentage = "5";

export default function ModalPartialSetDetails (props:any, handleClose:any, appBar:any)  {

/**
 * MAIN DATA OF CARDS
*/
    let maincardsinfo = "total cards: " + props.data.totalcards;
    let maincardlabels = [""];
    let glitchPercentage = Math.round(100*props.data.totalglitches/props.data.totalcards);
    let softwarePercentage = Math.round(100*props.data.totalsoftware/props.data.totalcards);

    

    if (!props.data.fromset) {
      maincardlabels = ["total glitches (" + glitchPercentage + "%)", "total software (" + softwarePercentage + "%)"]
      maincardsinfo = maincardsinfo.concat("\ntotal Glitches: " +props.data.totalglitches + " (" + props.data.uniqueglitches + " unique)");
      maincardsinfo = maincardsinfo.concat("\ntotal Software: " +props.data.totalsoftware + " (" + props.data.uniquesoftware + " unique)");
      maincardsinfo = maincardsinfo.concat("\ncards illustrated: " +props.data.illustrated + " / " + (props.data.uniqueglitches+props.data.uniquesoftware));
    } else {
      
      let highlightGlitchStyling =  "";
      let highlightSoftwareStyling =  "";
      
       
      
      if (!between(glitchPercentage, Number(GlitchTargetPercentage)-2, Number(GlitchTargetPercentage)+2)) highlightGlitchStyling = "style='color:red'";
      if (!between(softwarePercentage, Number(SoftwareTargetPercentage)-2, Number(SoftwareTargetPercentage)+2)) highlightSoftwareStyling = "style='color:red'";
      maincardlabels = ["total glitches (" + glitchPercentage + "% / " + GlitchTargetPercentage + "%)", "total software (" + softwarePercentage + "% / " + SoftwareTargetPercentage+ "%)"]
      maincardsinfo = maincardsinfo.concat("\n<span " + highlightGlitchStyling +">total Glitches: " + props.data.totalglitches + " (" + glitchPercentage +"%)</span>");
      maincardsinfo = maincardsinfo.concat("\n<span " + highlightSoftwareStyling +">total Software: " +props.data.totalsoftware + " (" + softwarePercentage +"%)</span>");
      maincardsinfo = maincardsinfo.concat("\ncards illustrated: " +props.data.illustrated + " / " + (props.data.uniqueglitches+props.data.uniquesoftware));
    }
    
/**
  * RARITY DATA
*/
    let rarityinfo = "";
    let rarityvalues:any = [];
    let raritylabels:any = [];
    props.data.rarities.map ((rarity:any) => {
      let rarityPercentage = Math.round(100*rarity.amount/props.data.totalcards);
      
      let raritylabel = rarity.id + " ("+  rarityPercentage + "%)";
      
      let highlightRarityStyling =  "";
      if (props.data.fromset) {  
        
        if (rarity.id == "Common") {
          
          if (!between(rarityPercentage, Number(CommonTargetPercentage)-2, Number(CommonTargetPercentage)+2)) highlightRarityStyling = "style='color:red'";
          raritylabel = rarity.id + " ("+  rarityPercentage + "% / " + CommonTargetPercentage + "%)";
        }
        
        if (rarity.id == "Uncommon") {
          if (!between(rarityPercentage, Number(UncommonTargetPercentage)-2, Number(UncommonTargetPercentage)+2)) highlightRarityStyling = "style='color:red'";
          raritylabel = rarity.id + " ("+  rarityPercentage + "% / " + UncommonTargetPercentage + "%)";
        }
        if (rarity.id == "Rare") {
          if (!between(rarityPercentage, Number(RareTargetPercentage)-2, Number(RareTargetPercentage)+2)) highlightRarityStyling = "style='color:red'";
          raritylabel = rarity.id + " ("+  rarityPercentage + "% / " + RareTargetPercentage + "%)";
        } 
        if (rarity.id == "Epic") {
          if (!between(rarityPercentage, Number(EpicTargetPercentage)-2, Number(EpicTargetPercentage)+2)) highlightRarityStyling = "style='color:red'";
          raritylabel = rarity.id + " ("+  rarityPercentage + "% / " + EpicTargetPercentage + "%)";
        } 
        if (rarity.id == "Legendary") {
          if (!between(rarityPercentage, Number(LegendaryTargetPercentage)-2, Number(LegendaryTargetPercentage)+2)) highlightRarityStyling = "style='color:red'";
          raritylabel = rarity.id + " ("+  rarityPercentage + "% / " + LegendaryTargetPercentage + "%)";
        }

        rarityinfo += "\n<span " + highlightRarityStyling +">"+rarity.id + ": " + rarity.amount + " (" + rarityPercentage + "%)</span>";
      } else {
        rarityinfo += "\n<span " + highlightRarityStyling +">"+rarity.id + ": " + rarity.amount + " (" + rarityPercentage + "%)</span>";
      }

      raritylabels.push(raritylabel);
      rarityvalues.push(rarity.amount);
    })


/**
 * SYNDICATE DATA
*/
    let syndicateinfo = "";
    let syndicatevalues:any = [];
    let syndicatelabels:any = [];
    props.data.syndicates.map ((syndicate:any) => {
      let syndicatePercentage = (props.data.fromset) ? Math.round(100*syndicate.amount/props.data.totalglitches) : Math.round(100*syndicate.amount/props.data.uniqueglitches);
      let syndicateName = syndicate.id.replace(/([A-Z])/g, ' $1').trim();
      
      let highlightSyndicateStyling =  "";

      if (props.data.fromset) {
        
        if (!between(syndicatePercentage, Number(SyndicateTargetPercentage)-2, Number(SyndicateTargetPercentage)+2)) highlightSyndicateStyling = "style='color:red'";
        syndicatelabels.push(syndicateName + " (" + syndicatePercentage + "% / " + SyndicateTargetPercentage + "%)");
      } else {
        syndicatelabels.push(syndicateName + " (" + syndicatePercentage + "%)");
      }
      syndicateinfo += "\n<span " + highlightSyndicateStyling + ">" +  syndicateName + ": " + syndicate.amount + " (" + syndicatePercentage + "%)</span>";
      syndicatevalues.push(syndicate.amount);
    })
  
/**
 * SUBTYPE DATA
*/
    let subtypeinfo = "";
    let subtypevalues:any = [];
    let subtypelabels:any = [];

    props.data.subtypes.map ((subtype:any) => {
      let subtypePercentage = Math.round(100*subtype.amount/props.data.totalglitches);
      subtypeinfo += "\n"+subtype.id.replace(/([A-Z])/g, ' $1').trim() + ": " + subtype.amount + " (" + subtypePercentage + "%)";
      subtypelabels.push(subtype.id.replace(/([A-Z])/g, ' $1').trim() + " (" + subtypePercentage + "%)");
      subtypevalues.push(subtype.amount);
    })

    // check if a card has a transform and add it in the list of subtypes

/**
 * TRANSFORM DATA
*/    
    let hasTransformAmount = 0;
    props.data.uniqueCardObjects.forEach ( (card:any) => {
      if (card.transformedCard.hasTransformed) hasTransformAmount++;
    })
    let currentTransformPercentage = Math.round((hasTransformAmount * 100) / props.data.totalcards);
    let transformsTargetAmount = Math.round(props.data.totalcards * (Number(TransformTargetPercentage) / 100));

    if (props.data.fromset) {
      
      if (!between(currentTransformPercentage, Number(TransformTargetPercentage)-2, Number(TransformTargetPercentage)+2)) {
        subtypeinfo += "\n\n<span style='color:red;'>Has transform:" + hasTransformAmount + "/" + transformsTargetAmount + "</span>";
      } else {
        subtypeinfo += "\n\nHas transform: " + hasTransformAmount + "/" + transformsTargetAmount;
      }
    } else {
      subtypeinfo += "\n\nHas transform: " + hasTransformAmount;
    }
    
    
/**
 * ENERGY COST DATA
*/
    let energycostinfo = "";
    let energycostvalues:any = [];
    let energycostlabels:any = [];
    props.data.manadistribution.map( (item:any) => {  
      energycostinfo += "\n"+item.id + ": " + item.amount + " cards ("+Math.round(item.percentage)+"%)";
      energycostlabels.push("energy: "+item.id);
      energycostvalues.push(item.amount);
    }) 
     
   
/**
 * PRIVATE CARDS AMOUNT
*/
    let privateCards: any[] = [];
    props.data.privateCards[0].forEach ( (item:any) => {
      privateCards.push(item.name);
    })
    

/**
 * NOT FUNCTIONAL CARDS AMOUNT
*/ 
    
    
    let notFunctionalCards: any[] = [];
    props.data.functional.map( (item:any) => {          
      if (item.functional == false) notFunctionalCards.push(item.name)
    })

/**
 * NOT ILLUSTRATED CARDS AMOUNT
*/ 
    let notIllustratedCards: any[] = [];
    props.data.functional.map( (item:any) => {          
      if (item.illustrated == false) notIllustratedCards.push(item.name)
    })


/**
 * CARDS IN A DROP AMOUNT
*/ 

    // let dropArray:any = [];
    // let dropValues:any = [];
    // let dropLabels:any = [];
    
    
    // props.data.uniqueCardObjects.forEach ( (obj:any) => {
    //   obj.sets.forEach ( (set:any) => {
    //     if (dropArray.indexOf(set.id) === -1) dropArray.push(set.id); // push to array only if the ID doesn't exist already
    //     let index = dropArray.indexOf(set.id);
    //     dropValues[index] = (dropValues[index] == undefined) ? 1 : dropValues[index]+1;
    //     dropLabels[index] =set.id;
    //   })
    // })

    
    return(
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}>
        
  
        {appBar(props, handleClose)}
        <Hidden/>
        {warnIfCardsAreNotFunctional ( notFunctionalCards, props.data.fromset)}
        { alertIfCardsArePrivate ( privateCards, props.data.fromset) }
        {infoIfCardsAreNotIllustrated ( notIllustratedCards, props.data.fromset)}
        
        <Grid container spacing={2} display="flex" sx={{m:2}}>          
          { ModalPartialCard("Cards", maincardsinfo, maincardlabels, [props.data.totalglitches, props.data.totalsoftware] ) }
          { ModalPartialCard("Rarities", rarityinfo, raritylabels, rarityvalues) }
          {/* {  (!props.data.fromset) ? ModalPartialCard("Drops", dropArray.join("\n"), dropLabels, dropValues) : "" } */}
          { ModalPartialCard("Syndicate", syndicateinfo, syndicatelabels, syndicatevalues) }
          { ModalPartialCard("Subtypes", subtypeinfo, subtypelabels, subtypevalues) }
          { (!props.data.fromset) ? ModalPartialCard("Energy Cost", energycostinfo, energycostlabels, energycostvalues): "" }
        </Grid>
        <Grid container spacing={2} display="flex" sx={{maxWidth:1280, margin:"0 auto"}}>          
          { (!props.data.fromset) ? createUniqueCardsTable(props.data.uniqueCardObjects): "" }
        </Grid>
       
      </Dialog>
    )
  
  }

  const alertIfCardsArePrivate = (privateCards:any, fromset:any) => {
    if (privateCards.length > 0 && !fromset) {
      return ( 
        <Alert severity="warning">Following {privateCards.length} cards are not public: {privateCards.join(', ')} </Alert>
      )
    } 
  }
  const warnIfCardsAreNotFunctional = (cards:any, fromset:any) => {
    if (cards.length > 0 && !fromset) {
      return (
        <Alert severity="error">Following {cards.length} cards are not functional: {cards.join(', ')} </Alert>
      )
    } 
  }
  const infoIfCardsAreNotIllustrated = (cards:any, fromset:any) => {
    if (cards.length > 0 && !fromset) {
      return (
        <Alert severity="info">Following {cards.length} cards are not illustrated: {cards.join(', ')} </Alert>
      )
    } 
  }


  const createUniqueCardsTable = (object:any) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));
    
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">attack / health</StyledTableCell>
              <StyledTableCell align="center">Talents</StyledTableCell>
              <StyledTableCell align="center">Energy cost</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align="center">Syndicate</StyledTableCell>
              <StyledTableCell align="center">Rarity</StyledTableCell>
              <StyledTableCell align="center">Set</StyledTableCell>
              
            </StyledTableRow>
          </TableHead>
          <TableBody>
            
            {Object.keys(object).map((key:any) => (
              
              <StyledTableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                <StyledTableCell sx={{maxWidth: 100, backgroundColor:'0xFF00FF'}} align="left">
                { (object[key].functional == false) ? <Warning color="error"/> : "" }
                { (object[key].public == false) ? <Error color="warning"/> : "" }
                { (object[key].image == null) ? <Error color="info"/> : "" }
                
                </StyledTableCell>
                <StyledTableCell sx={{maxWidth: 100}} align="left">{object[key].name}</StyledTableCell>
                <StyledTableCell sx={{maxWidth: 300}} align="left">{object[key].description}</StyledTableCell>
                <StyledTableCell sx={{maxWidth: 100}} align="center">{ (object[key].type == "Glitch") ? object[key].attack + " / " + object[key].health : "" }</StyledTableCell>
                <StyledTableCell sx={{maxWidth: 100}} align="center">{ object[key].abilities.join(', ') }</StyledTableCell>
                <StyledTableCell sx={{maxWidth: 50}}align="center">{object[key].cost.mana}</StyledTableCell>
                <StyledTableCell sx={{maxWidth: 100}}align="center">{object[key].type}</StyledTableCell>
                <StyledTableCell sx={{maxWidth: 100}}align="center">{ (object[key].type == "Glitch") ? object[key].syndicate.replace(/([A-Z])/g, ' $1').trim() : "" }</StyledTableCell>
                <StyledTableCell sx={{maxWidth: 100}}align="center">{object[key].rarity}</StyledTableCell>
                <StyledTableCell sx={{maxWidth: 100}} align="center">{ 
                  object[key].sets.map(function(item:any) {
                    return item['id'] + " ";
                  }) 
                }</StyledTableCell>
                
              </StyledTableRow>
           ))}
          </TableBody>
        </Table>
      </TableContainer> 
   )
  
  }


  const createProgress = (label:any, amount:any, percentage:any) => {
  
    return (
      <LinearProgressWithLabel label={label} amount={amount} percentage={percentage} /> 
    )
  }

  function LinearProgressWithLabel(props: LinearProgressProps & { label:string, amount:number, percentage: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1, minHeight:50 }}>
        <Typography variant="body2" color="text.secondary">{ props.label }</Typography>
          <LinearProgress variant="determinate" value={props.percentage} />
        </Box>
        <Box sx={{ minWidth: 135 }}>
          <Typography variant="body2" color="text.secondary">{props.amount} cards</Typography>
        </Box>
      </Box>
    );
  }

  function between(x:Number, min:Number, max:Number) {
    return x >= min && x <= max;
  }