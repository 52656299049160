import { SelectionController, SnackbarController, StorageSource } from "@camberi/firecms";
import JSZip from 'jszip';
import JSZipUtils from '../utils/JSZipUtils';
import { saveAs } from 'file-saver';


let _storage: StorageSource,
    _snackbar: SnackbarController,
    _files: any[] = [],
    _filesToDownload:number = 0,
    _this: any,
    _setLoading:any,
    _filesReady:Event;



export default class downloadImages {

    constructor(context: any) {
        _storage = context.storageSource;
        _snackbar = context.snackbarController;
        _this = this;
        _filesReady = new Event("filesready");
        document.removeEventListener('filesready',this.listener);
        document.addEventListener('filesready',this.listener);
    }


    public getRenderedCards (cards:any, setLoading:any) {
        _setLoading = setLoading;
        let totalFiles = 0;
        
        for (var i = 0; i < cards.length; i++) {
            let card = cards[i];
            card.renderedcards.forEach( () => {
                totalFiles++;
            })
        }
        for (var i = 0; i < cards.length; i++) {
            let card = cards[i];
            card.renderedcards.forEach( (c:any) => {
                this.createRenderedCardURLs(c.visual, totalFiles);
            })
        }        
    }



    private createRenderedCardURLs(url:any, totalFiles:any) {
        _storage.getDownloadURL(url).then((result: any) => {
            _files.push(result);
            _filesToDownload++;
            if (_filesToDownload >= totalFiles) document.dispatchEvent(_filesReady);
    
          });
    }
    public get (selectionController:SelectionController) {
        let totalFiles = 0;
        for (var i = 0; i < selectionController.selectedEntities.length; i++) {
            let item = selectionController.selectedEntities[i];
            if (item.values.Image != "" && item.values.Image != undefined) totalFiles++;
            if (item.values.Equipment != "" && item.values.Equipment != undefined) totalFiles++;

        }
        for (var i = 0; i < selectionController.selectedEntities.length; i++) {
            this.createURLs(selectionController.selectedEntities[i], totalFiles);
        }
    }

    private listener () {

        document.removeEventListener('filesready', this.listener);
        _filesToDownload = 0;
        _this.downloadImages();
    }

    private downloadImages () {
        let zip = new JSZip();
        
        _files.forEach ( (el, i) => {    
            let file = _this.urlToBlob(el, i);
            let filename =el.split("/").pop().split('%2F')[1].split("?")[0];
            zip.file (filename, file, {base64:true});
        });

        zip.generateAsync({type:"blob"}).then ( content => {
            saveAs(content, "file.zip");
            _setLoading(false);
        }) 
    }

    private async urlToBlob (url:string, i:number) {
        let imageBlob = await fetch(url).then(response => response.blob());
        let imgData = new File([imageBlob], i+'.png');

        return imgData;
    }
    
    private createURLs(item: {id: any;values: any}, totalFiles: number) {
        
        if (item.values.Image != "" && item.values.Image != undefined) {
            _storage.getDownloadURL(item.values.Image).then((result: any) => {
              _files.push(result);
              _filesToDownload++;
              console.log (_filesToDownload, totalFiles);
              if (_filesToDownload >= totalFiles) document.dispatchEvent(_filesReady);
      
            });
        }

        if (item.values.Equipment != "" && item.values.Equipment != undefined) {
            _storage.getDownloadURL(item.values.Equipment).then((result: any) => {

                _files.push(result);
               _filesToDownload++;
              if (_filesToDownload >= totalFiles) document.dispatchEvent(_filesReady);
      
            });
        }
    }

    private urlToPromise(url:string) {
        return new Promise(function(resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (err: any, data: unknown) {
                if(err) {
                    reject(err);
                } else {
                    
                    resolve(data);
                }
            });
        });
        }


}