import { buildSchema, ExtraActionsParams, buildCollection } from "@camberi/firecms";
import { Button, Tooltip } from "@mui/material";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { collection, collectionGroup, getDocs, query, where } from "firebase/firestore";
import Config from "../utils/config";


let _setOpen:any;
let _setData:any;

let _setMetrics:any;
let _setRewardWallets:any;
let _setTotalUsers: any;
let _setCurrencyExchange:any;
export default class Boosters {

    constructor() {



    }

    static BuildCollection (setOpen:any, setData:any, isadmin:boolean, issuperadmin:boolean, setRewardWallets:any, setMetrics:any, setTotalUsers:any, setCurrencyExchange:any, groupname:string) {
      _setOpen = setOpen;
      _setData = setData;
      _setMetrics = setMetrics;
      _setRewardWallets = setRewardWallets;
      _setTotalUsers = setTotalUsers;
      _setCurrencyExchange = setCurrencyExchange;

        



      return buildCollection({
        path: "boosters",
        exportable:false,
        group:groupname,
        schema: Boosters.schema(),
        name: "Booster packs",
        extraActions: Boosters.actions,
        callbacks: {
          onPreSave: ({values, context}) => {
              if (values != undefined) {
                let percent  = Number(values.dr_common) + Number(values.dr_uncommon) + Number(values.dr_rare) + Number(values.dr_epic) + Number(values.dr_legendary);
                percent = Math.round(percent * 100) / 100;
                let snackbar = context.snackbarController;
                snackbar.open({
                  type: (percent < 100 || percent > 100) ? "error" : "success",
                  title: "Drop Rate",
                  message: (percent < 100 || percent > 100) ? "Drop rates of " + values.name + " pack needs to be 100, currently are " + percent + "%" : "drop rate of " + values.name + " pack is " + percent
                });
              }
          return values;
          }
        },
//         textSearchEnabled: true,
        permissions: ({entity,path,user,authController,context}) => {
          
          return ({
              read: true,
              create: isadmin,
              update: isadmin,
              delete: isadmin
          });
        },


        subcollections: [
          buildCollection({
            name: "Settings",
            path: "public",
            exportable:false,
            schema: Boosters.publicSchema(),
            callbacks: {
              onPreSave: ({values, context}) => {
                
                  if (values != undefined) {
                    let msg ="";
                    if (values.type != "defaultdeck") msg += "Assign the 'booster' to the type.";
                    if (values.docid == "" || values.docid == undefined) msg += "Assign the parent document id to its field."
                    
                    let snackbar = context.snackbarController;
                    snackbar.open({
                      type: (msg == "") ? "success" : "error",
                      title: "Settings",
                      message: (msg == "") ? "settings are correct." : msg
                    });
                  }
              return values;
              }
            },
            permissions: ({entity,path,user,authController,context}) => {

              
              return ({
                  read: true,
                  create: issuperadmin, // TODO allow to create only one document
                  update: issuperadmin,
                  delete: issuperadmin
              });
            },
          }),
          
        ]
        
    });
  }


  static async getBoosterData () {
    let q = query( collection(Config.getLiveDB(), "boosters"), where("public", "==", true));
      let querySnapshot = await getDocs(q);
      let i = 0;
      let _boosterdata:any = {};
      _boosterdata.boosterinfo = true;
      _boosterdata.packs = [];
      querySnapshot.forEach(async (doc) => {
        _boosterdata.packs.push(doc.data());
        i++;
        if (i >= querySnapshot.size) {
          await Boosters.getMetrics();
          await Boosters.getReferralWallets();
          await Boosters.getCurrencyExchange();
          _setData(_boosterdata);
          _setOpen(true);
        }
      });
  }

  static async getReferralWallets () {
    let ta = query( collection(Config.getLiveDB(), "users"));
    let totalUsersSnapshot = await getDocs(ta);
    _setTotalUsers(totalUsersSnapshot.size);

    let q = query( collection(Config.getDevDB(), "sendlost"));
      let querySnapshot = await getDocs(q);
      let m = 0;
      let rewards:any = [];
      querySnapshot.forEach(async (doc) => {
        m++;
        if (doc.data().sent) rewards.push(doc.data().walletHash);
        if (m >= querySnapshot.size) {
          _setRewardWallets(rewards);
        }
        
      });
  }

  static async getCurrencyExchange () {
    fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USDC")
        .then((response) => response.json())
        .then((data) => {
          console.log ("CURRENCY EXCHANGE", data);
          _setCurrencyExchange(data);
        });
  }


  static async getMetrics () {
    let m = 0;
    let metricsQuery = query(collectionGroup(Config.getLiveDB(), "metrics"));    
    let metricsQuerySnapshot = await getDocs(metricsQuery);
    let _metricsdata:any = [];
    metricsQuerySnapshot.forEach((doc) => {
      _metricsdata.push(doc.data());
      m++;
      if (m >= metricsQuerySnapshot.size) {
        _setMetrics(_metricsdata);
      }
    })
  }

  static actions(extraActionParams:ExtraActionsParams) {

      const openModal = async () => {
        Boosters.getBoosterData();
      }

       return [
          
           <Tooltip title="get statistics of booster packs" key="meta-tip">
            <Button onClick={openModal} color="primary" key="meta">
              <QueryStatsIcon />
            </Button>
            </Tooltip>
          ];
     
  }

  
    static schema () {
        return buildSchema({
          name: "Booster",
          properties: {
            
            sets: {
              title: "Genesis Set",
              dataType: "reference",
              path:"sets",
              previewProperties: ["name" , "status"],
              validation: { required: true },

            },
            type: {
              title: "Type",
              dataType: "string",
              config: {
                enumValues: {
                    classic: "Classic",
                    exclusive: "Exclusive",
                    collectors: "Collectors"
                }
              },
              validation: { required: true },
            },
            startdate: {
              title: "sale starts at (EST)",
              dataType: "timestamp"
            },
            nongenesis: {
              title: "non-genesis sale",
              dataType: "boolean"
            },
            duedate: {
              title: "available until (EST)",
              dataType: "timestamp"
            },
            availability_01: {
              title: "Remaining Drop #1",
              dataType: "number",
              validation: { required: true },
            },
            availability_02: {
              title: "Remaining Drop #2",
              dataType: "number",
              validation: { required: true },
            },
            availability_03: {
              title: "remaining Drop #3",
              dataType: "number",
              validation: { required: true },
            },
            availability_04: {
              title: "remaining #4",
              dataType: "number",
              validation: { required: true },
            },
            availability_05: {
              title: "availability #5",
              dataType: "number",
              validation: { required: true },
            },
            availability_06: {
              title: "availability #6",
              dataType: "number",
              validation: { required: true },
            },
            name: {
                  title: "Name",
                  dataType: "string"
              },
              description: {
                title: "description",
                dataType: "string",
                columnWidth: 300,
                config: {
                  multiline: true
                },
            },
              cost: {
                dataType: "array",
                of: {
                  dataType:"map",
                  properties: {
                    currency: {
                      title: "Currency",
                      dataType: "string",
                      config: {
                        enumValues: {
                            usdc: "USDC",
                            eth: "ETH",
                            lost: "LOST"
                        }
                    }
        
                    },
                    cost: {
                      title: "Cost",
                      dataType: "number",
                      
                  },  
                  }
                }   
            },
            availableamounts: {
              dataType: "array",
              title:"available amounts",
              of: {
                dataType:"map",
                properties: {
                  amount: {
                    title: "Amount",
                    dataType: "number",
                  },
                  discount: {
                    title: "discount in %",
                    dataType: "number",
                    
                },  
                }
              }   
          },
              cardsInPack: {
                title: "Cards in pack",
                dataType: "number",
                validation: { required: true },
              },
              chances_classic: {
                title: "classic chances",
                description: "chances a card will convert to classic",
                dataType: "number"
              },
              chances_exclusive: {
                title: "exclusive chances",
                description: "chances a card will convert to exclusive",
                dataType: "number"
              },
              chances_collectors: {
                title: "collectors chances",
                description: "chances a card will convert to collectors",
                dataType: "number"
              },
              dr_common: {
                title: "DR (common)",
                dataType: "number",
                validation: { required: true },
              },
              dr_uncommon: {
                title: "DR (uncommon)",
                dataType: "number",
                validation: { required: true },
              },
              dr_rare: {
                title: "DR (rare)",
                dataType: "number",
                validation: { required: true },
              },
              dr_epic: {
                title: "DR (epic)",
                dataType: "number",
                validation: { required: true }, 
              },
              dr_legendary: {
                title: "DR (legendary)",
                dataType: "number",
                validation: { required: true },
              },
              public: {
                title: "public",
                dataType: "boolean"
            },
          }
        });
    }

    static publicSchema () {
      return buildSchema({ 
        name: "Settings",
        properties: {
            public: {
                title: "public",
                dataType: "boolean"
            },
            isAvailableForPurchase: {
              title: "isAvailableForPurchase",
              dataType: "boolean"
            },
            docid: {
              title: "parent document id",
              dataType: "string"
            },
            type: {
              title: "type",
              dataType: "string",
              config: {
                enumValues: {
                    booster: "booster",
                   
                }
              }
            },
                        
        }
      });  
    }
    
}