import printCards from "../utils/printCards";
import { buildSchema, EntityReference,buildProperty, ExtraActionsParams, buildCollection } from "@camberi/firecms";
import { Button, Tooltip } from "@mui/material";
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';

type card = {
  functional: any;
  //status:EntityReference,
  isPublished:boolean,
  public:boolean,
  sets: EntityReference[],
  setID: string,
  image:string,
  equipment: string,
  transformedCard: {isTransformed:boolean, transformedFrom:EntityReference,hasTransformed:boolean, transformsTo:EntityReference},
  abilities: [],

  name: string,
  description: string,
  cost:{mana:number, life:number},
  syndicate:string,
  rarity:string,
  type:string,
  subtype:string,
  attack:number,
  renderedcards: [],
  defaultRenderedCard:string,
  health:number
  //version:string
}


export default class Cards {

  static BuildCollection (isadmin:boolean, issuperadmin:boolean, groupname:string) {
    return buildCollection({
      path: "cards",
      exportable:false,
      group:groupname,
      extraActions:Cards.printCardsAction,
      textSearchEnabled: true,
      schema: Cards.schema(),
      name: "Cards",
      filterCombinations: [{subtype:"asc", image:"desc"}],
     
      //excludedProperties: ["Sets", "SetID", "nftid", "Cost", "Attack", "Health"],
      permissions: ({entity, path, user, authController, context }) => {
        
        return ({
            read: true,
            create: issuperadmin,
            update: issuperadmin,
            delete: issuperadmin
        });
      },

      subcollections: [
        buildCollection({
          name: "Settings",
          path: "public",
         // exportable:false,
          schema: Cards.publicSchema(),
          // permissions: ({entity,path,user,authController,context}) => {
          //   return ({
          //       read: true,
          //       create: false,
          //       update: issuperadmin,
          //       delete: issuperadmin
          //   });
          // },
        }),
        
      ]
    });
  };
  
    static printCardsAction(extraActionParams:ExtraActionsParams) {
        const onClick = (event: React.MouseEvent) => {
            let printcards = new printCards(extraActionParams.context);  
            printcards.createCardsPDF(extraActionParams.selectionController);
        };
        
        return (
          <Tooltip title="create a pdf of the selected cards">
            <Button onClick={onClick} color="primary">
                <PictureAsPdf/>
            </Button>
            </Tooltip>
        );
      
      }

    static schema () {
        return buildSchema<card>({
          name: "Card",
          
          properties: {
            
            name: {
              title: "Name",
              dataType: "string"
            },
            syndicate: {
              title: "Syndicate",
              dataType: "string",
              config: {
                enumValues: {
                    AdamantHands: {label: "Adamant Hands", color: "yellowLight" },
                    SentinelsOfEternity: {label: "Sentinels of Eternity", color: "orangeLight"},
                    CuratorsMaxima: { label: "Curators Maxima", color: "tealLight"},
                    SongOfTheChain: {label: "Song of the Chain", color: "grayDark" },
                    GuardiansOfTheSource: {label: "Guardians of the Source", color:"cyanLight"},
                    None: {label: "None", color: "grayLight"},
                }
              }
            },
            subtype: {
              title: "Subtype",
              dataType: "string",
              config: {
                enumValues: {  
                  
                  Mecha: { label: "Mecha", color: "yellowLight" },
                  Punk: { label: "Punk", color: "yellowLight" },
                  Scavenger: { label: "Scavenger", color: "yellowLight" },

                  Samurai: { label: "Samurai", color:"orangeLight" },
                  Legion: {label: "Legion", color: "orangeLight" },
                  Aristocrat: { label: "Aristocrat", color: "orangeLight" },
                  
                  Creature: { label: "Creature", color: "tealLight" },
                  Shaman: { label: "Shaman", color: "tealLight" },
                  Elemental: { label: "Elemental", color: "tealLight" },

                  Magus: { label: "Magus", color: "grayDark" },
                  ChaosAgent: { label: "Chaos Agent", color: "grayDark" },
                  Fabled: { label:"Fabled", color: "grayDark" },

                  Seer: { label: "Seer", color: "cyanLight" },
                  Purifier: { label: "Purifier", color: "cyanLight" },
                  Celestial: { label:"Celestial", color: "cyanLight" },

                  Installation: {label: "Installation Required", color:"grayLight" },
                  PlugAndPlay: {label: "Plug n Play", color: "grayLight" },
                  None: "None"
                }
              }
            },
            description: {
              title: "Description",
              dataType: "string"
            },
            attack: {
              title: "Attack",
              dataType: "number",
            },
            health: {
              title: "Health",
              dataType: "number",
            },
            abilities: {
              title: "Abilities",
              dataType: "array",
              of: {
                dataType: "string",
                config: {
                  enumValues: {
                    "Overflow":"Overflow",
                    "Predictive Strike": "Predictive Strike",
                    "Surge": "Surge",
                    "Extraction": "Extraction",
                    "Infected": "Infected",
                    "Regenarate": "Regenarate",
                    "Protector": "Protector",
                    "Aggressor": "Aggressor",
                    "Mirror": "Mirror",
                    "double intrusion": "double intrusion",
                    "bit thirsty": "bit thirsty",
                    "troll": "troll",
                    "Encrypted": "encrypted"
                    
                  }
                }
              }
            },


            isPublished: {
              title:"is published",
              dataType:"boolean"
            },

            
            functional: {
              title:"in-game functional",
              dataType:"boolean"
            },
            image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
              title: "Card image",
              dataType: "string",
              config: {
                  storageMeta: {
                      mediaType: "image",
                      storagePath: "cards",
                      acceptedFiles: ["image/*"]
                      
                  }
              }
            }),

            equipment: buildProperty({ // The `buildProperty` method is an utility function used for type checking
              title: "Object image",
              dataType: "string",
              config: {
                  storageMeta: {
                      mediaType: "image",
                      storagePath: "equipment",
                      acceptedFiles: ["image/*"]
                  }
              }
            }),
           
            defaultRenderedCard: buildProperty({ // The `buildProperty` method is an utility function used for type checking
              title: "Default rendered card",
              dataType: "string",
              config: {
                  storageMeta: {
                      mediaType: "image",
                      storagePath: "renderedcards",
                      acceptedFiles: ["image/*"]
                      
                  }
              }
            }),
            renderedcards: {
              dataType: "array",
              of : {
                dataType:"map",
                properties: {
                  visual:  buildProperty({ // The `buildProperty` method is an utility function used for type checking
                    title: "Card Visual",
                    dataType: "string",
                    config: {
                        storageMeta: {
                            storagePath: "renderedcards",
                          //  mediaType: "image",
                            acceptedFiles: ["image/*"]
                        }
                    }
                  }),

                  type: {
                    title: "Type",
                    description: "Type of rendered card",
                      dataType: "string",
                      config: {
                          enumValues: {
                              classic: "Classic",
                              exclusive: "exclusive",
                              collectors: "collectors",
                          }
                      }
                  }
                }
              }
            },
            
            
            type: {
              title: "Type",
              dataType: "string",
              config: {
                enumValues: {
                    Glitch: "Glitch",
                    Software: "Software"
                }
              }
            },
            
            cost: {
              title: "Cost",
              dataType: "map",
              columnWidth:100,
              
                properties: {
                  mana: {
                    title: "mana",
                    dataType: "number"
                  },
                  life: {
                    title: "life",
                    dataType: "number"
                  },
                  
                }
            },
            transformedCard: {
              title: "transformed Card",
              dataType: "map",
              columnWidth:100,

              properties: {
                isTransformed: {
                  title: "is transformed",
                  dataType: "boolean"
                },
                transformedFrom: {
                  title: "transformed from",
                  dataType: "reference",
                  path: "cards"
                },
                hasTransformed: {
                  title: "has transformed",
                  dataType: "boolean"
                },
                transformsTo: {
                  title: "transforms to",
                  dataType: "reference",
                  path: "cards"
                }
              }
            },
            
            
          
            // visibility: {
            //   title: "visibility",
            //   dataType: "string",
            //   config: {
            //     enumValues: {
            //         Development: "in development",
            //         Public: "Public"
            //     }
            //   }
            // },  
         
           
           
            rarity: {
              title: "Rarity",
              dataType: "string",
              config: {
                enumValues: {
                    Common: "Common",
                    Uncommon: "Uncommon",
                    Rare: "Rare",
                    Epic: "Epic",
                    Legendary: "Legendary",
                }
              }
            },
            
              sets: {
                title: "Set",
                dataType: "array",
                of: {
                  dataType: "reference",
                  path: "sets"
              }
  
              },
              setID: {
                title: "Set ID",
                dataType: "string",
              },

              public: {
                title:"marked as public",
                dataType:"boolean",

              },

              

              // version: {
              //   title:"version",
              //   dataType:"string"
              // }
              
     
              
             
          }
        });
        
    }

    static publicSchema () {
      return buildSchema({ 
        name: "Settings",
        properties: {
            public: {
                title: "public",
                dataType: "boolean"
            },
            docid: {
              title: "parent document id",
              dataType: "string"
          },
            type: {
              title: "type",
              dataType: "string",
              config: {
                enumValues: {                   
                    card: "card",

                }
              }
            },
                        
        }
      });  
    }   
    
    
    

}