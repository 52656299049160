import * as React from 'react';
import CompareIcon from "@mui/icons-material/Compare";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

import { red } from '@mui/material/colors';
import config from "../utils/config";
import { ref, getDownloadURL } from "firebase/storage";


import diff from "microdiff";

import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';

import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { ArrowForward, Download, ExpandMore, Upload } from '@mui/icons-material';
import {  Accordion, AccordionDetails, AccordionSummary, LinearProgress, Link, ListItem, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { useAuthController, useSideEntityController, useSnackbarController, useStorageSource } from '@camberi/firecms';
import downloadImages from "../utils/downloadImages";
import { connected } from 'process';



let sideEntityController:any, snackbarController:any, authController:any, storageSource:any, _setOpen:any,_setModalData:any;





export default function DashboardTile(props: {index:number, title: string; subtitle:string;collection:string, fields:any, setOpen:any, setModalData:any }) {
    _setOpen = props.setOpen;
    _setModalData = props.setModalData;

    const [liveitems, setLiveItems] = React.useState([]); // the live collections
    const [subitems, setDevSubitems] = React.useState([]); // the cards within the dev collection
    const [livesubitems, setLiveSubitems] = React.useState([]); // the cards within the live collection
    const [loading, setLoading] = React.useState(false); // progress bar when downloading
    const [accordiontitle, setAccordiontitle] = React.useState("Cards"); // accordion title for cards
    
    
    sideEntityController = useSideEntityController();
    snackbarController = useSnackbarController();
    authController = useAuthController();
    storageSource = useStorageSource();
    
    

    React.useEffect(()=>{
          
      if (props.collection == "sets") {
        // getPublicLiveCollections(props.collection, setLiveItems);
        getDevChildrenOfCollections(props.fields.cards, setDevSubitems);
        setAccordiontitle(props.fields.cards.length +" Cards");
      }
      //isSuperAdmin(authController.user);
    }, []);

  
   
  return (
    
    <Card key={props.title} sx={{ m: 2, width:350 }}>
      {loading && <LinearProgress></LinearProgress>  }
      { cardHeaderComponent (props) }
      
      {accordionList(accordiontitle, subitems)}
      
      <CardActions disableSpacing>
          { downloadImagesComponent (props.collection, subitems, setLoading) }    
          { compareToLiveComponent(props,liveitems, setLiveItems, setDevSubitems, setLiveSubitems) }
      </CardActions>
      {loading && <LinearProgress></LinearProgress>  }
    </Card>
  );
  
}



const getCardsFromLiveSet = async ( collectionID:any, setItems:any) => {
  try{
    
  
  let q = query( collection(config.getLiveDB(), "sets"), where("__name__", "==", collectionID));
  let querySnapshot = await getDocs(q);
  let promise = new Promise(async (resolve, reject) => { 
    await querySnapshot.forEach( ( doc) => {
      let data:any = doc.data();

      if (data == undefined) return;
      data.id = doc.id;
      
      
      resolve(data);
    })
  
  });
  
  let result: any;
  if (querySnapshot.size == 0) {
    result = {};
  } else {
    result = await promise;
  }
  
  
  setItems(result)
  return result;    
  } catch(e) {
    console.log (e);
  }

};


const getDevChildrenOfCollections = async (cards : any, setSubitems:any) => {
  let subItemsArray : any = [];
  let i = 0;
  let removeCounter = 0;
  
  let promise = new Promise((resolve, reject) => {
    
    cards.forEach (async ( card :any, index:any ) => {
      try {
        const cardSnap = await getDoc(card);
        let d:any = cardSnap.data();
        
        if (d == undefined) return;
        
        d.id = card.id;
     //   console.log (index, "DEV", card.id, d.name);
        
        
        subItemsArray.push (d);
        i++;
        if (i >= cards.length) {
          setSubitems(subItemsArray);
          resolve(subItemsArray);        
        }
      } catch (e:any) {
        console.log ("DEV", card.id, e);
        removeCounter++;
        
      } finally {
        if (i == cards.length - removeCounter) resolve(subItemsArray);
      }
      
  
    })


  
  });

  let result = await promise;
  return result;

 
  
}

const getLiveChildrenOfCollections = async (cards : any, setSubitems:any) => {
  let subItemsArray : any = [];
  let i = 0;
  let removeCounter = 0;
  let promise = new Promise((resolve, reject) => {
    cards.forEach (async ( card :any, index:any ) => {

      try {

      
      const cardSnap = await getDoc(card);
      let d:any = cardSnap.data();
      if (d == undefined) return;
      d.id = card.id;
    //  console.log (index, "LIVE", card.id, d.name);
      
      subItemsArray.push (d);
      i++;
      if (i >= cards.length) {
        setSubitems(subItemsArray); 
        resolve(subItemsArray);
        
      }
  
    } catch (e:any) {
      
      removeCounter++;
    } finally {
      console.log (i, cards.length-removeCounter);
      if (i == cards.length-removeCounter) resolve(subItemsArray);
    }
    })
    
    
  });

  let result = await promise;
  return result;
  
}



const compareToLiveComponent = ( props:any, liveitem:any,setLiveItems:any, setDevSubitems:any, setLiveSubitems:any) => {

  if (props.collection != "sets") return; // compare sets only
  let title =  "compare dev to live";

  
  
  return (
    <Tooltip title={title}>
      <span>
          <IconButton aria-label={title} onClick={async (e) => { 
            await getCardsFromLiveSet(props.fields.docid, setLiveItems);
            
            
            if (Object.keys(liveitem).length == 0) {
              snackbarController.open({
                type: "info",
                title: props.fields.name,
                message: "this set is not on the live server"
              });

              return;
            }

            
            let r1:any;
            let r2:any;
            r1 = await getDevChildrenOfCollections(props.fields.cards, setDevSubitems);
            r2 = await getLiveChildrenOfCollections(liveitem.cards, setLiveSubitems); 

            let devTable:any = [];

            // GET URLS OF DEV SEVER
            await Promise.all(r1.map(async (card:any) => {

              let devCardReferenceCard:any;
              if ( card.renderedcards[0].visual != undefined) {
                devCardReferenceCard = await getDownloadURL(ref(config.getDevStorage(), card.renderedcards[0].visual));
              } else {
                devCardReferenceCard = undefined;
              }
              
              let cardID = card.id;
              
              devTable.push ( {"id": cardID, "devRarity":card.rarity, "devURL": devCardReferenceCard })
            }))


            // GET URLS OF LIVE SEVER
            await Promise.all(r2.map(async (card:any) => {
              let devCardReferenceCard:any;
              if ( card.renderedcards[0].visual != undefined) {
                devCardReferenceCard = await getDownloadURL(ref(config.getLiveStorage(), card.renderedcards[0].visual));
              } else {
                devCardReferenceCard = undefined;
              }
              
              let cardID = card.id;
              
              let cardExistsInTable = devTable.some ( (tableCard:any) => {
                if (tableCard.id == cardID) {
                  return true; 
                } 
                return false;
                
              })

              if (cardExistsInTable) {
                let entryIndex = devTable.findIndex((card: { id: any; }) => card.id === cardID);
                devTable[entryIndex].liveURL = devCardReferenceCard;
                devTable[entryIndex].liveRarity = card.rarity;
              }
              if (!cardExistsInTable) devTable.push ( {"id": cardID, "liveRarity":card.rarity, "liveURL": devCardReferenceCard })

            }))
            
              let data = {
                comparison: true,
                header:"Compare",
                cardDiffrerences:devTable
              };
     
               _setModalData(data);
               _setOpen(true);
    
        
          }}>
          <CompareIcon />
      </IconButton>
      </span>  
  </Tooltip>
  )
}



const downloadRenders = async (cards:any, setLoading:any) => {
  let context = {
    storageSource: storageSource,
    snackbarController:snackbarController
  }
  let download = new downloadImages(context);
  download.getRenderedCards(cards, setLoading);
  
}

const cardHeaderComponent = (props:any) => {
  let collectionURL = "/c/"+props.collection;
  let letterAvatar = props.title.substring(0,1).toUpperCase();
  return (
    <CardHeader key={collectionURL}
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {letterAvatar}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings" href={collectionURL}>
            <ArrowForward />
          </IconButton>
        }
        title={props.title}
        subheader= {props.subtitle}
      />
  )
}



const downloadImagesComponent = (collection:string, cards:any, setLoading:any) => {
  if (collection == "sets") {
    let title = "download rendered images";
    return (
      <Tooltip title={title}>
          <span>
            <IconButton aria-label="download" onClick={(e) => { 
               e.preventDefault();
               setLoading(true);
              downloadRenders(cards, setLoading); 
              }}>
              <Download />
            </IconButton>
          </span>
          </Tooltip>
    )
  }
}


const accordionList = ( accordiontitle:any,subitems:any) => {

    return (
      <Accordion onChange = {async (e,expanded) => {
       
      }}>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
        <ListItemText primary={accordiontitle} /> 
        </AccordionSummary>
        <AccordionDetails>
        {

        subitems.map( (item:any,i:any) => {
          let counter = i+1;
         // let publicstring = (item['public'] == false) ? "private" : "public";
       //   let color = (item['public'] == false) ? "#f44336" : "auto";
          return (
            <ListItem key={i}>
                <ListItemText 
                  sx={{ color:"auto"}}
                  primary={counter + ". " +  item['name']}  />
                
                <IconButton aria-label="settings" onClick={ (e) => {
                  e.preventDefault();
                  sideEntityController.open({ 
                    entityId: item['id'],
                    path: "/cards"
                  })
                }}>
                  <KeyboardTabIcon />
                </IconButton>
            </ListItem>
          
          )
        }) }
      </AccordionDetails>
    </Accordion>
    )

}