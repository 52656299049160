import { AppBar, Divider, IconButton,Toolbar, Typography } from "@mui/material";
import * as React from 'react';
import { Close, CurrencyExchange } from "@mui/icons-material";
import { useSideEntityController } from '@camberi/firecms';

import modalPartialBooster from './ModalPartialBooster';
import modalPartialCardDetails from './ModalPartialCardDetails';
import modalPartialComparisonTable from './ModalPartialComparisonTable';
import ModalPartialSetDetails from "./ModalPartialSetDetails";
import modalPartialLOSTSentResult from "./ModalPartialLOSTSentResult";

let sideEntityController:any;

export function Modal (props: { open:any, setOpen:any, data:any, metrics:any, rewards:any, totalUsers:any, currencyExchange:any; theme:any }) {
  
  sideEntityController = useSideEntityController();

  if (props.data.length === 0 ) return (<Divider />); 
  const handleClose = () => {props.setOpen(false);};
  
  if (props.data.cardsfeedback == true) {
    return(modalPartialCardDetails(props, handleClose, appBar))
  } else if (props.data.comparison == true) {
    return (modalPartialComparisonTable(props, handleClose,appBar,sideEntityController))
  } else if (props.data.boosterinfo == true) {
    return (modalPartialBooster(props, handleClose, appBar))
  } else if (props.data.lostsent == true) {
    return (modalPartialLOSTSentResult(props,handleClose,appBar));
  }else {
    return ( ModalPartialSetDetails(props,handleClose,appBar) )
  }
}




const appBar = (props:any, handleClose:any) => {
  return(
    <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.data.header} Metadata
          </Typography>
          
        </Toolbar>
      </AppBar>
  )
}


